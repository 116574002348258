import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

import Layout from "Components/Layout/Layout";

import {
  createCohort,
  createCourseByStep,
  createSchool,
  getCourseBySlug,
  getSchoolBySlug,
  TCohort,
  TCourse,
  TCourseObjective,
  TSchool,
  updateCohortById,
  updateCourseByStep,
  updateSchoolById,
} from "Services";
import Progress from "./Components/Progress";
import CourseDetails from "../Components/CourseDetails";
import SuccessModal from "Components/Modals/Success";
import AlertModal from "Components/Modals/Alert";
import errors from "Utilities/errors";
import { useApp } from "Context/AppContext";
import endpoints from "Services/endpoints";
import Step4 from "./Step4";
import useSWR from "swr";

const getCourseObjectives = (data: string): TCourseObjective[] | [] => {
  if (!data) return [];

  let parsedData = JSON.parse(data);

  if (typeof parsedData === "string") {
    // parsedData = JSON.parse(parsedData);
    return getCourseObjectives(parsedData);
  }

  return parsedData;
};

const getTutorId = (value: string) => {
  if (!value) return null;
  return Number(value.split("-")[0]);
};

const Course = () => {
  // Router
  const [searchParams, setSearchParams] = useSearchParams();
  const { id, schoolSlug } = useParams();
  const { data: schoolResponse } = useSWR(
    endpoints.GET_SCHOOL_BY_SLUG(schoolSlug || ""),
    getSchoolBySlug
  );

  const [course, setCourse] = useState<Partial<TCourse>>({
    name: "",
    cover_image: "",
    overview: "",
    description: "",
    website_title: "",
    course_objective: [{ id: 1, value: "" }],
    difficulty_level: "",
    publish: false,
    target_audiences: [
      {
        category: "",
        description: "",
      },
      {
        category: "",
        description: "",
      },
      {
        category: "",
        description: "",
      },
      {
        category: "",
        description: "",
      },
    ],
  });

  const [cohort, setCohort] = useState<Partial<TCohort>>({
    name: "",
    full_price: 0,
    start_date: "",
    application_deadline: "",
    duration: "",
    tutor_id: null,
    location: "",
    certification: null,
    commitment: "",
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [schoolId, setSchoolId] = useState<number>();

  const currentStep = searchParams.get("step");

  const getStepTitle = () => {
    if (currentStep === "3") return "Who should enrol in this course?";
    if (currentStep === "4") return "Create a cohort for this course";
    if (currentStep === "5") return "Review course information";
    return id !== "create" ? "Edit Course" : "Create a new course";
  };

  //   Effects
  useEffect(() => {
    if (!currentStep) {
      setSearchParams({ step: "1" });
    }

    if (id !== "create") {
      //make api call
      getCourseBySlug(endpoints.GET_COURSE_BY_SLUG(id || "")).then((res) => {
        const { target_audiences, ...rest } = res.data;
        setCourse((state) => ({
          ...rest,
          target_audiences: target_audiences
            ? target_audiences
            : state.target_audiences,
          course_objective: getCourseObjectives(
            (res.data.course_objective as string) || ""
          ),
        }));

        setCohort((state) =>
          res.data?.cohorts?.length ? res.data.cohorts[0] : state
        );
      });
    }
  }, []);

  const navigate = useNavigate();

  const { setNotifications } = useApp();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const formData = new FormData();

      // const { image, facts_cover_image, ...rest } = course;

      Object.entries(course).forEach(([key, value]) => {
        formData.append(key, value?.toString() || "");
      });

      // formData.append("image", image || "");
      // formData.append("facts_cover_image", facts_cover_image || "");

      if (id === "create") {
        const { data: response } = await createSchool(formData);

        setSchoolId(response.id);

        setShowSuccessModal(true);

        return;
      }

      await updateSchoolById(course.id || "", formData);

      setNotifications([
        {
          title: "Successfully updated school",
          severity: "success",
          id: Date.now(),
        },
      ]);

      setTimeout(() => {
        navigate("/schools");
      }, 1000);
    } catch (error) {
      const errorMessage = errors.format(error);

      setNotifications([
        { title: errorMessage, severity: "error", id: Date.now() },
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCreateCourse = async () => {
    try {
      setIsSubmitting(true);

      if (id === "create") {
        const { data: response } = await createCourseByStep(
          schoolResponse?.data.id.toString() || "",
          {
            name: course.name,
            overview: course.overview,
            difficulty_level: course.difficulty_level,
            course_objective: course.course_objective,
          }
        );

        setCourse((state) => ({ ...state, id: response.id }));

        setNotifications([
          {
            title: "Successfully created course",
            severity: "success",
            id: Date.now(),
          },
        ]);

        setTimeout(() => {
          navigate(`/courses/${schoolSlug}/${response.slug}?step=2`);
        }, 1000);

        return;
      }

      await updateCourseByStep(course.id?.toString() || "", "step-1", {
        name: course.name,
        overview: course.overview,
        difficulty_level: course.difficulty_level,
        course_objective: course.course_objective,
      });

      setNotifications([
        {
          title: "Successfully updated course",
          severity: "success",
          id: Date.now(),
        },
      ]);

      setTimeout(() => {
        setSearchParams({ step: "2" });
      }, 1000);
    } catch (err) {
      const errorMessage = errors.format(err);

      setNotifications([
        { title: errorMessage, severity: "error", id: Date.now() },
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateCourseStep2 = async () => {
    try {
      setIsSubmitting(true);

      const formData = new FormData();
      formData.append("cover_image", course.cover_image || "");
      formData.append("description", course.description || "");

      await updateCourseByStep(course.id?.toString() || "", "step-2", formData);

      setNotifications([
        {
          title: "Successfully updated course",
          severity: "success",
          id: Date.now(),
        },
      ]);

      setTimeout(() => {
        setSearchParams({ step: "3" });
      }, 1000);
    } catch (err) {
      const errorMessage = errors.format(err);

      setNotifications([
        { title: errorMessage, severity: "error", id: Date.now() },
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateCourseStep3 = async () => {
    try {
      setIsSubmitting(true);

      await updateCourseByStep(course.id?.toString() || "", "step-3", {
        target_audiences: course.target_audiences,
      });

      setNotifications([
        {
          title: "Successfully updated course",
          severity: "success",
          id: Date.now(),
        },
      ]);

      setTimeout(() => {
        setSearchParams({ step: "4" });
      }, 1000);
    } catch (err) {
      const errorMessage = errors.format(err);

      setNotifications([
        { title: errorMessage, severity: "error", id: Date.now() },
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitCohort = async () => {
    try {
      setIsSubmitting(true);

      if (!course?.cohorts?.length) {
        // create
        await createCohort(course.id?.toString() || "", {
          ...cohort,
          tutor_id: getTutorId(cohort.tutor_id?.toString() || ""),
          full_price: Number(cohort.full_price),
          committment: cohort.commitment,
        });

        setNotifications([
          {
            title: "Successfully created course cohort",
            severity: "success",
            id: Date.now(),
          },
        ]);

        navigate("/courses");
        return;
      }

      await updateCohortById(cohort.id?.toString() || "", {
        ...cohort,
        tutor_id: getTutorId(cohort.tutor_id?.toString() || ""),
        full_price: Number(cohort.full_price),
        committment: cohort.commitment,
      });

      setNotifications([
        {
          title: "Successfully updated course cohort",
          severity: "success",
          id: Date.now(),
        },
      ]);

      setTimeout(() => {
        navigate("/courses");
      }, 1000);
    } catch (err) {
      const errorMessage = errors.format(err);

      setNotifications([
        { title: errorMessage, severity: "error", id: Date.now() },
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Layout>
      <SuccessModal
        showModal={showSuccessModal}
        title="School created successfully"
        message={`You've created ${course.name} on iṣẹ́ School. You can now proceed to add courses and customise the learning environment.`}
        primaryButtonText="Add Course"
        onPrimaryButtonClick={() => {
          navigate(`/schools/${schoolId}/add-course?step=1`);
        }}
        secondaryButtonText="Done"
        onSecondaryButtonClick={() => {
          navigate("/schools");
        }}
      />

      <AlertModal
        showModal={showAlertModal}
        title="Cancel school creation?"
        message="You'll lose all information and start over if you cancel."
        primaryButtonText="Yes, cancel"
        onPrimaryButtonClick={() => {
          navigate("/schools");
        }}
        secondaryButtonText="No, don't cancel"
        onSecondaryButtonClick={() => {
          setShowAlertModal(false);
        }}
      />

      <div className="bg-white w-[940px] ml-6 mt-6 py-7 px-7 rounded-[10px] mb-10">
        <Progress steps={[1, 2, 3, 4, 5]} />

        <h2 className="text-[31px] font-medium mb-6">{getStepTitle()}</h2>

        <div>
          {currentStep === "1" ? (
            <Step1
              course={course}
              setCourse={setCourse}
              onSaveAndContinue={handleCreateCourse}
              isSaving={isSubmitting}
            />
          ) : currentStep === "2" ? (
            <Step2
              course={course}
              setCourse={setCourse}
              onSaveAndContinue={handleUpdateCourseStep2}
              onCancel={() => {
                setShowAlertModal(true);
              }}
            />
          ) : currentStep === "3" ? (
            <Step3
              course={course}
              setCourse={setCourse}
              onSaveAndContinue={handleUpdateCourseStep3}
              onCancel={() => {
                setShowAlertModal(true);
              }}
            />
          ) : currentStep === "4" ? (
            <Step4
              cohort={cohort}
              setCohort={setCohort}
              onCancel={() => {
                setShowAlertModal(true);
              }}
            />
          ) : currentStep === "5" ? (
            <CourseDetails
              isSubmitting={isSubmitting}
              course={course}
              cohort={cohort}
              onCancel={() => {
                setShowAlertModal(true);
              }}
              onSubmit={handleSubmitCohort}
            />
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default Course;
