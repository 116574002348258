import { createColumnHelper } from "@tanstack/react-table";
import Table from "Components/Table";
import Tabs, { a11yProps } from "Components/Tabs";
import { useState } from "react";

type TCourse = {
  id: number;
  courseName: string;
  amount: string;
  paymentDate: string;
  paymentType: string;
  paymentStatus: string;
};

const StudentPayments = () => {
  const columnHelper = createColumnHelper<TCourse>();

  const tabs = [
    { name: "Paid courses", slug: 1 },
    { name: "Refund", slug: 2 },
  ];

  const columns = [
    columnHelper.accessor("courseName", {
      header: "Course name",
    }),
    columnHelper.accessor("amount", {
      header: "Total amount",
    }),
    columnHelper.accessor("paymentDate", {
      header: "Payment date",
    }),
    columnHelper.accessor("paymentType", {
      header: "Payment Type",
    }),
    columnHelper.accessor("paymentStatus", {
      header: "Payment Status",
    }),
  ];

  const courses = [
    {
      id: 1,
      courseName: "Customer Success Management [May, 2024]",
      amount: "₦40,000.00",
      paymentDate: "12 Dec, 2024",
      paymentType: "Full payment",
      paymentStatus: "Paid in full",
    },
    {
      id: 2,
      courseName: "M365 Technical Support [Dec, 2024]",
      amount: "₦40,000.00",
      paymentDate: "12 May, 2024",
      paymentType: "Instalment payment",
      paymentStatus: "Paid in full",
    },
  ];

  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (value: number | string) => {
    setActiveTab(value as number);
  };

  return (
    <div className="px-6">
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="basic tabs example"
      >
        {tabs.map((tab, index) => (
          <Tabs.Item
            className={`h-[65px] px-8 rounded-t-[10px] ${
              activeTab === tab.slug ? "bg-white" : "bg-[#EFEFEF]"
            }`}
            label={tab.name}
            identifier={tab.slug}
            {...a11yProps(tab.slug)}
            key={`student-payments-tab-${index}`}
          />
        ))}
      </Tabs>

      <div className="bg-white px-10 py-8">
        <Table columns={columns} data={courses} isLoading={false} />
      </div>
    </div>
  );
};

export default StudentPayments;
