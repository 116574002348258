const Filter = (props: {
  pageNumber: number;
  lastPage: number;
  pageSize: number;
  setPageNumber: (value: number) => void;
  handleChange: (value: string) => void;
}) => {
  const { pageNumber, lastPage, pageSize, handleChange } = props;

  const isFirstPage = props.pageNumber <= 1;
  const isLastPage = props.pageNumber === props.lastPage;

  return (
    <div className="h-[81px] bg-white rounded-[10px] flex items-center justify-between px-5">
      <div className="h-[49px] relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="size-5 absolute top-1/2 -translate-y-1/2 left-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>

        <input
          type="text"
          className="h-full border rounded-[10px]  pl-12 pr-4 w-[300px] focus:w-[350px] transition-all duration-300"
          placeholder="Search by name or email"
          onChange={({ target }) => handleChange(target.value)}
        />
      </div>

      <div className="flex items-center gap-x-9">
        <span>
          {pageNumber}-{lastPage} of {pageSize}
        </span>

        <div className="flex gap-x-6">
          <button
            className={`${
              isFirstPage ? "bg-gray-300 cursor-not-allowed" : "bg-[#664EFE]"
            } !w-[30px] !h-[30px] !px-0 rounded-full flex items-center justify-center`}
            disabled={isFirstPage}
            onClick={() => props.setPageNumber(props.pageNumber - 1)}
          >
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
              <path
                d="M12.5 16.3307L6.66667 10.4974L12.5 4.66406"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>

          <button
            className={`${
              isLastPage ? "bg-gray-300 cursor-not-allowed" : "bg-[#664EFE]"
            } !w-[30px] !h-[30px] !px-0 rounded-full flex items-center justify-center`}
            disabled={isLastPage}
            onClick={() => props.setPageNumber(props.pageNumber + 1)}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              className="rotate-180"
            >
              <path
                d="M12.5 16.3307L6.66667 10.4974L12.5 4.66406"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
