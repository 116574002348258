import { Dispatch, SetStateAction } from "react";
import { TCourse } from "Services";
import UploadInput from "./Components/UploadInput";
import Button from "Components/Button/Button";
import { useSearchParams } from "react-router-dom";
import TextArea from "Components/TextArea/TextArea";

interface IProps {
  course: Partial<TCourse>;
  setCourse: Dispatch<SetStateAction<Partial<TCourse>>>;
  onSaveAndContinue: () => void;
  onCancel: () => void;
}

const Step2 = (props: IProps) => {
  const { course, setCourse, onCancel, onSaveAndContinue } = props;
  const [_, setSearchParams] = useSearchParams();

  return (
    <>
      <div className="mb-5">
        <UploadInput
          label="Upload course image"
          value={course.cover_image || ""}
          onChange={(file) => {
            setCourse((state) => ({ ...state, cover_image: file[0] }));
          }}
        />
      </div>

      <TextArea
        isRequired
        label="Course description"
        placeholder="Start typing..."
        name="description"
        onChange={(e) => {
          setCourse((state) => ({ ...state, description: e.target.value }));
        }}
        value={course.description || ""}
      />

      <div className="flex items-center justify-between border-t border-[#D8D8D8] pt-6 mt-10">
        <div>
          <button
            className="text-lg font-medium text-[#664EFE]"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>

        <div className="flex gap-x-6">
          <div className="w-[101px]">
            <Button
              type="secondary"
              onClick={() => {
                setSearchParams({ step: "1" });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Button onClick={onSaveAndContinue} disabled={!course.description}>
              Save and continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step2;
