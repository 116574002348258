import { Dispatch, SetStateAction } from "react";
import { TCourse, TCourseObjective } from "Services";

import classes from "./Step1.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextArea from "Components/TextArea/TextArea";
import Input from "Components/Input/Input";
import Button from "Components/Button/Button";
import DropdownWithSearch from "Components/DropdownWithSearch/DropdownWithSearch";

interface IProps {
  course: Partial<TCourse>;
  setCourse: Dispatch<SetStateAction<Partial<TCourse>>>;
  onSaveAndContinue: () => void;
  isSaving: boolean;
}

const PlusIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.5V20.5M20 12.5L4 12.5"
      stroke="#664EFE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const isValidCourseObjectives = (items: TCourseObjective[]) => {
  return items.every((item) => item.value);
};

const Step1 = (props: IProps) => {
  const { course, setCourse, onSaveAndContinue, isSaving } = props;

  const navigate = useNavigate();

  return (
    <section className={classes.container}>
      <div>
        <Input
          isRequired
          label="Name of course"
          placeholder="E.g Talent Acquisition Course"
          name="name"
          onChange={(e) => {
            setCourse((state) => ({ ...state, name: e.target.value }));
          }}
          value={course.name || ""}
        />

        <TextArea
          isRequired
          label="Course overview"
          placeholder="E.g Our Talent Aquisition course equips students with a holistic approach to building secure and resilient software
systems in the cloud."
          name="overview"
          onChange={(e) => {
            setCourse((state) => ({ ...state, overview: e.target.value }));
          }}
          value={course.overview || ""}
        />

        <DropdownWithSearch
          title="Select option"
          label="Course difficulty level *"
          selected={course.difficulty_level}
          setSelected={(value) =>
            setCourse((state) => ({ ...state, difficulty_level: value }))
          }
          options={[
            "Beginner course",
            " Intermediate course",
            "Advanced course",
          ]}
        />

        <div>
          {(course?.course_objective as TCourseObjective[])?.map(
            (objective, index) => (
              <div className="flex items-center">
                <div className="flex-grow">
                  <Input
                    isRequired
                    label={
                      index === 0 ? "List the objectives of this course " : ""
                    }
                    placeholder="E.g Develop a deep understanding of Talent Acquisition strategies and practices "
                    name="course_objective"
                    onChange={(e) =>
                      setCourse((state) => ({
                        ...state,
                        course_objective: (
                          state.course_objective as TCourseObjective[]
                        ).map((item, i) =>
                          i === index
                            ? { ...item, value: e.target.value }
                            : item
                        ),
                      }))
                    }
                    value={objective.value || ""}
                  />
                </div>

                {index !== 0 && (
                  <button
                    className="font-medium text-red-500 mb-6"
                    onClick={() =>
                      setCourse((state) => ({
                        ...state,
                        course_objective: (
                          state.course_objective as TCourseObjective[]
                        ).filter((_, i) => i !== index),
                      }))
                    }
                  >
                    Remove
                  </button>
                )}
              </div>
            )
          )}
        </div>

        <button
          className="text-[#664EFE] font-medium flex items-center !p-0 gap-x-2"
          onClick={() =>
            setCourse((state) => ({
              ...state,
              course_objective: [
                ...(state.course_objective as TCourseObjective[]),
                {
                  id:
                    (state.course_objective as TCourseObjective[])?.length + 1,
                  value: "",
                },
              ],
            }))
          }
        >
          <PlusIcon />
          Add more objectives
        </button>
      </div>

      <div className={classes.buttonSection}>
        <Button
          type="secondary"
          onClick={() => {
            navigate("/courses");
          }}
        >
          <span>Cancel</span>
        </Button>

        <Button
          loading={isSaving}
          onClick={onSaveAndContinue}
          disabled={
            !course.name ||
            !course.overview ||
            !course.difficulty_level ||
            !isValidCourseObjectives(
              course.course_objective as TCourseObjective[]
            )
          }
        >
          <span>Save and continue</span>
        </Button>
      </div>
    </section>
  );
};

export default Step1;
