import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import classes from "./QuillComponent.module.css";
import { countWords } from "../../HelperFunctions/wordCount";

type QuillComponentTypes = {
  state: string;
  onChange: (e: any) => void;
  label?: string;
  max?: number;
};

// State

const QuillComponent = ({
  state,
  onChange,
  label,
  max,
}: QuillComponentTypes) => {
  // States
  const [wordCount, setWordCount] = useState(0);

  return (
    <div className={classes.quillContainer}>
      {label && <label>{label}</label>}
      <ReactQuill
        value={state}
        onChange={(e) => {
          setWordCount(countWords(e));
          onChange(e);
        }}
        className={classes.container}
        theme="snow"
      />
      {
        <span
          style={
            wordCount > (max as number)
              ? { color: "#dc362e" }
              : { color: "#2e2e2e" }
          }
          className={classes.tip}
        >
          Your word count is {wordCount}
        </span>
      }
    </div>
  );
};

export default QuillComponent;
