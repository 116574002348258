import React, { useEffect, useState } from "react";
import classes from "./StudentDataTable.module.css";
import greenBar from "../../../Assets/Images/greenBar.svg";
import yellowBar from "../../../Assets/Images/yellowBar.svg";
import StudentsManagementModulesEmptyTab from "./StudentsManagementModulesEmptyTab";
// import StudentDataTableContent from "./StudentDataTableContent";
// import useStudents from "../../../Hooks/useStudents";
// import Loader from "../../../Components/Loader/Loader";
import moment from "moment";
import Filter from "../../../Components/Table/Filter";
import Table from "../../../Components/Table";
import Pagination from "../../../Components/Table/Pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { getStudents, TStudent } from "../../../Services";

const columnHelper = createColumnHelper<TStudent>();

const StudentDataTable = () => {
  // States
  // const [students, setStudent] = useState([]);

  // Requests
  // const { isLoading, data } = useStudents();

  // States
  const [students, setStudents] = useState<Partial<TStudent>[]>([]);
  const [lastPage, setLastPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);

  // Requests
  // const { isLoading, data } = useTutors();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const currentPage = searchParams.get("current_page") || 1;
  const searchQuery = searchParams.get("search") || "";

  const getFullName = (firstName = "", lastName = "") => {
    let result = "";

    if (firstName) {
      result += firstName;
    }

    if (lastName) {
      result += " " + lastName;
    }

    return result || "N.A";
  };

  const columns = [
    columnHelper.accessor("first_name", {
      header: "Name of student",
      cell: (data) => {
        const fullName = getFullName(
          data.row.original.first_name || "",
          data.row.original.last_name || ""
        );

        return <div>{fullName}</div>;
      },
    }),
    columnHelper.accessor("email", {
      header: "Email address",
    }),
    columnHelper.accessor("last_name", {
      header: "Course",
      cell: () => <div>N.A</div>,
    }),
    columnHelper.accessor("id", {
      header: "",
      cell: (data) => (
        <button
          className="font-medium"
          onClick={() => navigate("/users/students/" + data.getValue())}
        >
          View
        </button>
      ),
    }),
  ];

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { data: response } = await getStudents({
          ...(currentPage && { page: currentPage }),
          ...(searchQuery && { search: searchQuery }),
        });

        setLastPage(response.last_page || 1);
        setTotal(response.total || 0);
        setStudents(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [currentPage, searchQuery]);

  // Effects
  // useEffect(() => {
  //   if (data?.data?.data) {
  //     setStudent(
  //       data?.data?.data?.map((data: any) => {
  //         return { ...data, isActive: false };
  //       })
  //     );
  //   }
  // }, [data?.data?.data]);

  const engagement = [
    {
      status: "Up",
      statusFigure: 2.1,
      totalNumber: total,
      image: greenBar,
      title: "All students",
    },
    {
      status: "Dn",
      statusFigure: 5.1,
      totalNumber:
        students?.filter((data: any) => data?.status === "active").length || 0,
      image: yellowBar,
      title: "Active students",
    },
    {
      status: "Up",
      statusFigure: 2.1,
      totalNumber:
        students
          ?.map((data: any) => {
            return moment().diff(data?.created_at, "days");
          })
          .filter((filter: any) => {
            return filter < 14;
          })?.length || 0,
      image: greenBar,
      title: "New students",
    },
  ];

  return (
    <section>
      <div className={`${classes.filterButton} mb-8`}>
        <span>Filter by:</span>
        <div>
          <button type="button">School</button>
          <button type="button">Cohort</button>
          <button type="button">Course</button>
        </div>
      </div>

      <div className="flex flex-col gap-5 mb-8">
        {engagement.map((data, i) => {
          return (
            <div key={i} className={classes.engagementCard}>
              <div>
                <h3 className={classes.title}>{data.title}</h3>
                <h1 className={classes.number}>
                  {data.totalNumber.toString()}
                </h1>
              </div>
              <div>
                <img src={data.image} alt={data.title} />
                <span>
                  {data.status} {data.statusFigure}%
                </span>
              </div>
            </div>
          );
        })}
      </div>

      {students?.length ? (
        // <StudentDataTableContent students={students} setStudents={setStudent} />
        <>
          <div className="mb-8">
            <Filter
              pageNumber={Number(currentPage)}
              lastPage={lastPage}
              pageSize={10}
              setPageNumber={(value) =>
                setSearchParams({
                  search: searchQuery,
                  current_page: value.toString(),
                })
              }
              handleChange={(value) => {
                setSearchParams({ search: value });
              }}
            />
          </div>

          <div className="bg-white py-6 px-8 rounded-t-[10px]">
            <Table
              columns={columns}
              data={students || []}
              isLoading={isLoading}
            />
          </div>

          <Pagination
            pageNumber={Number(currentPage)}
            lastPage={lastPage}
            setPageNumber={(value) =>
              setSearchParams({
                search: searchQuery,
                current_page: value.toString(),
              })
            }
          />
        </>
      ) : (
        <StudentsManagementModulesEmptyTab />
      )}
    </section>
  );
};

export default StudentDataTable;
