import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { TSchool } from "Services";
import UploadInput from "./Components/UploadInput";
import Input from "Components/Input/Input";
import Button from "Components/Button/Button";
import { useSearchParams } from "react-router-dom";

interface IProps {
  school: Partial<TSchool>;
  setSchool: Dispatch<SetStateAction<Partial<TSchool>>>;
  onCancel: () => void;
}

const Step2 = (props: IProps) => {
  const { school, setSchool, onCancel } = props;
  const [_, setSearchParams] = useSearchParams();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    setSchool((state) => ({ ...state, [target.name]: target.value }));
  };

  return (
    <>
      <div className="mb-5">
        <UploadInput
          label="Upload cover image"
          value={school.image || ""}
          onChange={(file) => {
            setSchool((state) => ({ ...state, image: file[0] }));
          }}
        />
      </div>

      <Input
        isRequired
        label="Tagline title"
        name="tagline_title"
        value={school.tagline_title || ""}
        placeholder="E.g Did you know?"
        onChange={handleChange}
      />

      <Input
        isRequired
        label="Facts"
        name="facts"
        value={school.facts || ""}
        placeholder="E.g Did you know?"
        onChange={handleChange}
      />

      <Input
        isRequired
        label="Annual salary range"
        name="salary_range"
        value={school.salary_range || ""}
        placeholder="E.g $80,000 - $150,000"
        onChange={handleChange}
      />

      <div className="flex items-center justify-between border-t border-[#D8D8D8] pt-6 mt-10">
        <div>
          <button
            className="text-lg font-medium text-[#664EFE]"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>

        <div className="flex gap-x-6">
          <div className="w-[101px]">
            <Button
              type="secondary"
              onClick={() => {
                setSearchParams({ step: "1" });
              }}
            >
              Back
            </Button>
          </div>

          <div className="w-[99px]">
            <Button
              onClick={() => {
                setSearchParams({ step: "3" });
              }}
              disabled={
                !school.tagline_title || !school.facts || !school.salary_range
              }
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step2;
