import { useParams } from "react-router-dom";
import ProfileSectionContainer from "../../../Components/ProfileSectionContainer/ProfileSectionContainer";

import useSWR from "swr";
import endpoints from "../../../Services/endpoints";
import { getStudent } from "../../../Services";

const UserIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 5.83333C13.3333 7.67428 11.8409 9.16667 9.99996 9.16667C8.15901 9.16667 6.66663 7.67428 6.66663 5.83333C6.66663 3.99238 8.15901 2.5 9.99996 2.5C11.8409 2.5 13.3333 3.99238 13.3333 5.83333Z"
        stroke="#737373"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99996 11.6667C6.7783 11.6667 4.16663 14.2783 4.16663 17.5H15.8333C15.8333 14.2783 13.2216 11.6667 9.99996 11.6667Z"
        stroke="#737373"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const AcademicIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 11.6693L17.5 7.5026L10 3.33594L2.5 7.5026L10 11.6693ZM10 11.6693L15.1326 8.81782C15.5848 9.95809 15.8333 11.2013 15.8333 12.5026C15.8333 13.087 15.7832 13.6597 15.687 14.2167C13.5119 14.4281 11.5409 15.3365 10 16.7156C8.45914 15.3365 6.48809 14.4281 4.31301 14.2167C4.2168 13.6597 4.16667 13.087 4.16667 12.5025C4.16667 11.2013 4.4152 9.95807 4.8674 8.81781L10 11.6693ZM6.66667 16.6692V10.4193L10 8.56742"
        stroke="#737373"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const BuildingIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 17.5V4.16667C15.8333 3.24619 15.0871 2.5 14.1667 2.5H5.83333C4.91286 2.5 4.16667 3.24619 4.16667 4.16667V17.5M15.8333 17.5L17.5 17.5M15.8333 17.5H11.6667M4.16667 17.5L2.5 17.5M4.16667 17.5H8.33333M7.5 5.83331H8.33333M7.5 9.16665H8.33333M11.6667 5.83331H12.5M11.6667 9.16665H12.5M8.33333 17.5V13.3333C8.33333 12.8731 8.70643 12.5 9.16667 12.5H10.8333C11.2936 12.5 11.6667 12.8731 11.6667 13.3333V17.5M8.33333 17.5H11.6667"
        stroke="#737373"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const LocationPinIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7141 13.8807C13.9865 14.6083 12.519 16.0758 11.4135 17.1813C10.6324 17.9624 9.36763 17.9623 8.58658 17.1813C7.50097 16.0957 6.0605 14.6552 5.286 13.8807C2.6825 11.2772 2.6825 7.05612 5.286 4.45262C7.88949 1.84913 12.1106 1.84913 14.7141 4.45262C17.3176 7.05612 17.3176 11.2772 14.7141 13.8807Z"
        stroke="#737373"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 9.16667C12.5 10.5474 11.3808 11.6667 10 11.6667C8.61933 11.6667 7.50004 10.5474 7.50004 9.16667C7.50004 7.78596 8.61933 6.66667 10 6.66667C11.3808 6.66667 12.5 7.78596 12.5 9.16667Z"
        stroke="#737373"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const EmailIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33329 16.6693C2.87496 16.6693 2.4826 16.5061 2.15621 16.1797C1.82982 15.8533 1.66663 15.4609 1.66663 15.0026V5.0026C1.66663 4.54427 1.82982 4.15191 2.15621 3.82552C2.4826 3.49913 2.87496 3.33594 3.33329 3.33594H16.6666C17.125 3.33594 17.5173 3.49913 17.8437 3.82552C18.1701 4.15191 18.3333 4.54427 18.3333 5.0026V15.0026C18.3333 15.4609 18.1701 15.8533 17.8437 16.1797C17.5173 16.5061 17.125 16.6693 16.6666 16.6693H3.33329ZM9.99996 10.8359L3.33329 6.66927V15.0026H16.6666V6.66927L9.99996 10.8359ZM9.99996 9.16927L16.6666 5.0026H3.33329L9.99996 9.16927ZM3.33329 6.66927V5.0026V15.0026V6.66927Z"
        fill="#737373"
      />
    </svg>
  );
};

type StudentProfileTypes = {
  student: any;
  referrals: any;
};

const StudentProfile = ({ student, referrals }: StudentProfileTypes) => {
  const { StudentId: id } = useParams();

  const { data: response } = useSWR(
    endpoints.GET_STUDENT_PROFILE(id || ""),
    getStudent
  );

  return (
    <>
      <ProfileSectionContainer
        header="Student profile"
        paragraph="View detailed profile information about a student"
      >
        <div className="flex gap-x-7">
          {/* <img src={student?.profile_image || user} alt="User" /> */}
          <div className="w-[80px] h-[80px] bg-gray-200 rounded-full"></div>

          <div>
            <h2 className="text-xl font-medium mb-3">
              {response?.data.first_name} {response?.data.last_name}
            </h2>

            <div className="flex mb-5">
              <div className="text-[#737373] font-medium flex gap-x-2 items-center min-w-[200px]">
                <UserIcon />
                <span>{response?.data.gender}</span>
              </div>

              <div className="flex items-center gap-x-2">
                <AcademicIcon />
                <span className="text-[#737373] font-medium">
                  Undergraduate
                </span>
              </div>
            </div>

            <div className="flex mb-5">
              <div className="text-[#737373] font-medium flex gap-x-2 items-center min-w-[200px]">
                <BuildingIcon />
                <span>{response?.data.employement_status}</span>
              </div>

              <div className="flex items-center gap-x-2">
                <LocationPinIcon />
                <span className="text-[#737373] font-medium">
                  {response?.data.state}, {response?.data.country}
                </span>
              </div>
            </div>

            <div className="flex">
              <div className="text-[#737373] font-medium flex gap-x-2 items-center">
                <EmailIcon />
                <span>{response?.data.email}</span>
              </div>
            </div>
          </div>
        </div>
      </ProfileSectionContainer>
    </>
  );
};

export default StudentProfile;
