import Layout from "Components/Layout/Layout";
import CourseDetails from "../Components/CourseDetails";
import useSWR from "swr";
import endpoints from "Services/endpoints";
import { getCourseBySlug, TCourseObjective } from "Services";
import { useParams } from "react-router-dom";

const getCourseObjectives = (data: string): TCourseObjective[] | [] => {
  if (!data) return [];

  let parsedData = JSON.parse(data);

  if (typeof parsedData === "string") {
    // parsedData = JSON.parse(parsedData);
    return getCourseObjectives(parsedData);
  }

  return parsedData;
};

const ViewCourse = () => {
  const { id } = useParams();

  const { isLoading, data: response } = useSWR(
    endpoints.GET_COURSE_BY_SLUG(id || ""),
    getCourseBySlug
  );

  const course = {
    ...response?.data,
    course_objective: getCourseObjectives(
      (response?.data.course_objective as string) || ""
    ),
  };

  return (
    <Layout>
      <div className="bg-white w-[940px] ml-6 mt-6 py-7 px-7 rounded-[10px] mb-10">
        <h2 className="text-[31px] font-medium mb-6">
          View Course Information
        </h2>

        <CourseDetails
          isLoading={isLoading}
          course={course}
          cohort={response?.data?.cohorts![0] || {}}
        />
      </div>
    </Layout>
  );
};

export default ViewCourse;
