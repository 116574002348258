import { useContext } from "react";
import { AuthUserContext } from "Context/AuthUserContext";
import classes from "./SchoolProgress.module.css";

interface IProps {
  showProgress?: boolean;
  steps: number[];
}

const SchoolProgress = ({ showProgress = true, steps }: IProps) => {
  // Context
  const { searchParams } = useContext(AuthUserContext);

  const userStep = searchParams.get("step");

  // Progress
  const progress = userStep ? steps.indexOf(parseInt(userStep)) + 1 : 0;

  return (
    <section>
      {showProgress && (
        <div className={classes.stepIndicator}>
          {steps.map((data) => {
            return (
              <div
                className={`${classes.step} ${
                  userStep && userStep >= data.toString()
                    ? classes.active
                    : classes.inActive
                }`}
                key={data}
              >
                <div>{data}</div>
              </div>
            );
          })}
          <p>
            Progress {progress} of {steps.length}
          </p>
        </div>
      )}
    </section>
  );
};

export default SchoolProgress;
