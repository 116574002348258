import { Dispatch, SetStateAction } from "react";

import Input from "Components/Input/Input";
import Button from "Components/Button/Button";

import { TCourse } from "Services";
import { useSearchParams } from "react-router-dom";
import TextArea from "Components/TextArea/TextArea";

interface IProps {
  course: Partial<TCourse>;
  setCourse: Dispatch<SetStateAction<Partial<TCourse>>>;
  onSaveAndContinue: () => void;
  onCancel: () => void;
}

const Step3 = (props: IProps) => {
  const { course, setCourse, onCancel, onSaveAndContinue } = props;
  const [_, setSearchParams] = useSearchParams();

  const isValid = () => {
    return course.target_audiences?.every(
      (item) => item.category && item.description
    );
  };

  return (
    <>
      {course.target_audiences?.map((audience, index) => (
        <div>
          <Input
            isRequired
            label={`Target audience ${index + 1}: Category`}
            value={audience.category}
            placeholder="E.g., Aspiring web developer"
            onChange={({ target }) =>
              setCourse((state) => ({
                ...state,
                target_audiences: state.target_audiences?.map((item, i) =>
                  index === i ? { ...item, category: target.value } : item
                ),
              }))
            }
          />

          <TextArea
            isRequired
            label={`Target audience ${index + 1}: Description`}
            placeholder="A description of how the course benefits this specific audience....."
            name="description"
            onChange={({ target }) =>
              setCourse((state) => ({
                ...state,
                target_audiences: state.target_audiences?.map((item, i) =>
                  index === i ? { ...item, description: target.value } : item
                ),
              }))
            }
            value={audience.description}
          />
        </div>
      ))}

      <div className="flex items-center justify-between border-t border-[#D8D8D8] pt-6 mt-10">
        <div>
          <button
            className="text-lg font-medium text-[#664EFE]"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>

        <div className="flex gap-x-6">
          <div className="w-[101px]">
            <Button
              type="secondary"
              onClick={() => {
                setSearchParams({ step: "2" });
              }}
            >
              Back
            </Button>
          </div>

          <div className="w-[99px]">
            <Button onClick={onSaveAndContinue} disabled={!isValid()}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step3;
