import { ReactNode } from "react";
import Modal from "./Modal";

import IMAGE_DISCARED_IDEA from "Assets/Images/discarded-idea-icon.svg";

interface IProps {
  showModal: boolean;
  title: string;
  message: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
  children?: ReactNode;
  image?: string;
}

const Alert = (props: IProps) => {
  const {
    showModal,
    title,
    message,
    children,
    primaryButtonText,
    secondaryButtonText,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    image,
  } = props;

  return (
    <Modal showModal={showModal}>
      <div className="bg-white w-[90%] lg:w-[876px] rounded-[10px] pt-16 pb-16">
        <div className="h-[280px] w-[280px] mx-auto">
          <img
            src={image ? image : IMAGE_DISCARED_IDEA}
            alt="alert_image"
            className="h-full w-full object-cover"
          />
        </div>

        <h2 className="text-[25px] font-medium text-center mt-4">{title}</h2>

        <p className="text-center max-w-[425px] mx-auto text-[#737373] mt-3.5">
          {message}
        </p>

        {children ? (
          children
        ) : (
          <div className="flex justify-center mt-6 gap-x-6">
            {secondaryButtonText && (
              <button
                className="border-2 border-[#2E2E2E] text-[#2E2E2E] h-[46px] px-6 rounded-[5px] font-medium"
                onClick={onSecondaryButtonClick && onSecondaryButtonClick}
              >
                {secondaryButtonText}
              </button>
            )}

            <button
              className="bg-[#DC362E] text-white h-[46px] px-6 rounded-[5px] font-medium"
              onClick={onPrimaryButtonClick}
            >
              {primaryButtonText}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Alert;
