import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { getTutors, TCohort, TTutor } from "Services";
import Button from "Components/Button/Button";
import { useSearchParams } from "react-router-dom";
import Input from "Components/Input/Input";
import DropdownWithSearch from "Components/DropdownWithSearch/DropdownWithSearch";
import errors from "Utilities/errors";
import { useApp } from "Context/AppContext";

interface IProps {
  cohort: Partial<TCohort>;
  setCohort: Dispatch<SetStateAction<Partial<TCohort>>>;
  onCancel: () => void;
}

const Step4 = (props: IProps) => {
  const { cohort, setCohort, onCancel } = props;
  const [_, setSearchParams] = useSearchParams();
  const { setNotifications } = useApp();

  const [tutors, setTutors] = useState<TTutor[]>([]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    setCohort((state) => ({ ...state, [target.name]: target.value }));
  };

  useEffect(() => {
    getTutors()
      .then((res) => setTutors(res.data.data))
      .catch((err) => {
        const errorMessage = errors.format(err);

        setNotifications([
          { title: errorMessage, severity: "error", id: Date.now() },
        ]);
      });
  }, []);

  return (
    <>
      <Input
        isRequired
        label="Cohort name"
        name="name"
        value={cohort?.name || ""}
        placeholder="E.g., Aspiring web developer"
        onChange={handleChange}
      />

      <Input
        isRequired
        label="Learning commitment"
        name="commitment"
        value={cohort?.commitment || ""}
        placeholder="E.g., Aspiring web developer"
        onChange={handleChange}
      />

      <Input
        type="date"
        isRequired
        label="Cohort application deadline"
        name="application_deadline"
        value={cohort?.application_deadline || ""}
        placeholder="E.g., Aspiring web developer"
        onChange={handleChange}
      />

      <Input
        type="date"
        isRequired
        label="Cohort start date"
        name="start_date"
        value={cohort?.start_date || ""}
        placeholder="E.g., Aspiring web developer"
        onChange={handleChange}
      />

      <DropdownWithSearch
        title="Select option"
        label="Cohort duration"
        selected={cohort?.duration}
        setSelected={(value) =>
          setCohort((state) => ({ ...state, duration: value }))
        }
        options={[
          "1 month",
          "2 month",
          "3 month",
          "4 month",
          "5 month",
          "6 month",
        ]}
      />

      <DropdownWithSearch
        title="Select option"
        label="Cohort location"
        selected={cohort?.location}
        setSelected={(value) =>
          setCohort((state) => ({ ...state, location: value }))
        }
        options={["virtual", "online"]}
      />

      <DropdownWithSearch
        title="Select option"
        label="Course certification"
        selected={cohort?.certification}
        setSelected={(value) =>
          setCohort((state) => ({ ...state, certification: value }))
        }
        options={["yes", "no"]}
      />

      <DropdownWithSearch
        title="Select option"
        label="Assign a tutor to this cohort"
        selected={cohort.tutor_id}
        setSelected={(value) =>
          setCohort((state) => ({ ...state, tutor_id: value }))
        }
        options={tutors.map(
          (tutor) => `${tutor.id} - ${tutor.first_name} ${tutor.last_name}`
        )}
      />

      <Input
        isRequired
        label="Enter course full price"
        name="full_price"
        value={cohort?.full_price?.toString() || ""}
        placeholder="E.g., Aspiring web developer"
        onChange={handleChange}
      />

      <div className="flex items-center justify-between border-t border-[#D8D8D8] pt-6 mt-10">
        <div>
          <button
            className="text-lg font-medium text-[#664EFE]"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>

        <div className="flex gap-x-6">
          <div className="w-[101px]">
            <Button
              type="secondary"
              onClick={() => {
                setSearchParams({ step: "3" });
              }}
            >
              Back
            </Button>
          </div>

          <div className="w-[275px]">
            <Button
              onClick={() => {
                setSearchParams({ step: "5" });
              }}
              disabled={false}
            >
              Review Information
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step4;
