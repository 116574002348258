import classes from "./StudentCourseEnrolled.module.css";
import ProgressBar from "../../../Components/ProgressBar/ProgressBar";

import IMAGE_COURSE_IMAGE from "Assets/Images/assignCourseImage.svg";

const StudentCourseEnrolled = () => {
  const enrolledCourses = [
    {
      courseImage: IMAGE_COURSE_IMAGE,
      courseTitle: "Frontend Development [May, 2024]",
      enrollProgressStatus: "100%",
      enrollProgressPercent: 75,
      courseProgressStatus: "Ongoing",
      status: "Paid",
    },
  ];

  return (
    <div className={classes.container}>
      <ol>
        {enrolledCourses.map((course, i) => {
          return (
            <div key={i} className={classes.body}>
              <li className={classes.listItem}>
                <img src={course.courseImage} alt={course.courseTitle} />

                <div className="w-full">
                  <div className="flex items-center justify-between mb-10">
                    <h4 className="font-medium">{course.courseTitle}</h4>

                    <div className="text-[#515151]">
                      {"Enrolled"}, {"Ongoing"}
                    </div>
                  </div>

                  <ProgressBar
                    height="16px"
                    primaryColor="#FFEDAD"
                    secondaryColor="#FDC500"
                    percentage={course.enrollProgressPercent}
                  />
                </div>
              </li>
            </div>
          );
        })}
      </ol>
    </div>
  );
};

export default StudentCourseEnrolled;
