import SchoolCreatingLayout from "../../../Components/SchoolCreatingLayout/SchoolCreatingLayout";
import classes from "./CreateBlogAddDetails.module.css";
import Button from "../../../Components/Button/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import Input from "../../../Components/Input/Input";
import { createBlogpostDataType } from "../../../Types/requestTypes";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import QuillComponent from "../../../Components/QuillComponent/QuillComponent";
import { inputChangeHandler } from "../../../HelperFunctions/inputChangeHandler";
import { MAX_BLOG_WORD_COUNT } from "../../../Utilities/constants";
import { countWords } from "../../../HelperFunctions/wordCount";

type CreateBlogAddDetailsProps = {
  createBlogPostData?: createBlogpostDataType;
  setCreateBlogPostData?: Dispatch<SetStateAction<createBlogpostDataType>>;
};

const CreateBlogAddDetails = ({
  createBlogPostData,
  setCreateBlogPostData,
}: CreateBlogAddDetailsProps) => {
  // Navigate
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [, setSearchParams] = useSearchParams();

  // States
  const [content, setContent] = useState("");

  // Effects
  useEffect(() => {
    if (content && setCreateBlogPostData) {
      setCreateBlogPostData((prevState) => {
        return { ...prevState, content };
      });
    }
  }, [content]);

  return (
    <SchoolCreatingLayout steps={[1, 2, 3]}>
      <section className={classes.container}>
        <h2>Create blogpost content</h2>
        <p>Start blogposts with suitable titles and create your content here</p>

        <div>
          <Input
            isRequired
            maxLength={100}
            label="Blogpost title (Maximum character count: 100)"
            placeholder="E.g 5 ways to document your website codes"
            name="title"
            value={createBlogPostData?.title}
            onChange={(e) => inputChangeHandler(e, setCreateBlogPostData)}
          />

          <QuillComponent
            state={content || (createBlogPostData?.content as string)}
            onChange={(e) => setContent(e)}
            label={"Type blogpost content * (Maximum word count:500) "}
            max={MAX_BLOG_WORD_COUNT}
          />
        </div>

        <div className={classes.buttonSection}>
          <Button
            type="secondary"
            onClick={() => {
              navigate("/blogs");
            }}
          >
            <span>Cancel</span>
          </Button>
          <Button
            disabled={
              !createBlogPostData?.title ||
              !createBlogPostData?.content ||
              countWords(createBlogPostData.content) > MAX_BLOG_WORD_COUNT
            }
            onClick={() => {
              setSearchParams({ step: "2" });
            }}
          >
            <span>Next</span>
          </Button>
        </div>
      </section>
    </SchoolCreatingLayout>
  );
};

export default CreateBlogAddDetails;
