import { useNavigate, useParams } from "react-router-dom";
import Layout from "Components/Layout/Layout";
import classes from "./SchoolCourseCreatedContainer.module.css";
import addButton from "Assets/Images/addButtonWithPurpleBackgroundAndRoundedCorners.svg";
import SchoolCourseCard from "Components/SchoolCourseCard/SchoolCourseCard";
import courseImage from "Assets/Images/courseImage.svg";
import useSWR from "swr";
import endpoints from "Services/endpoints";
import { getSchoolCoursesBySlug } from "Services";
import Breadcrumbs from "Components/Breadcrumbs/Breadcrumbs";
import breadcrumbsBack from "Assets/Images/breadcrumbsBack.svg";

const SchoolCourseCreatedContainer = () => {
  // Router
  const navigate = useNavigate();
  const { schoolSlug } = useParams();

  const { data: response } = useSWR(
    endpoints.GET_SCHOOL_COURSES_BY_SLUG(schoolSlug || ""),
    getSchoolCoursesBySlug
  );

  const courses = response?.data || [];

  const breadCrumbs = {
    image: breadcrumbsBack,
    array: [
      {
        title: `${schoolSlug}`,
        route: "/courses",
      },
      {
        title: "Courses",
        route: `/courses/${schoolSlug}`,
      },
    ],
  };

  return (
    <Layout>
      <Breadcrumbs {...breadCrumbs} />

      <div className={`${classes.container} px-6 py-6`}>
        <div className={classes.emptyTab}>
          <img
            onClick={() => navigate(`/courses/${schoolSlug}/create?step=1`)}
            src={addButton}
            alt="add course"
          />

          <div>
            <h2>Add a new course</h2>
            <p>Create a new course connected to this school.</p>
          </div>
        </div>

        {courses?.map((course) => (
          <SchoolCourseCard
            key={course?.courses_id}
            id={course?.courses_id}
            image={course?.courses_cover_image || courseImage}
            title={course.courses_name}
            cohortNumber={course.cohortcount}
            description={course.courses_description || "No description"}
            slug={course.courses_slug}
          />
        ))}
      </div>
    </Layout>
  );
};

export default SchoolCourseCreatedContainer;
