import Layout from "../Components/Layout/Layout";
import BlogManagementContainer from "../Containers/BlogManagementPageContainer/BlogManagementContainer/BlogManagementContainer";

const BlogManagementPage = () => {
  return (
    <Layout>
      <BlogManagementContainer />
    </Layout>
  );
};

export default BlogManagementPage;
