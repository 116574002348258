const endpoints = {
  GET_PROFILE: "/students/profile",
  GET_MY_PROFILE: "/admin/my-profile",
  UPDATE_CONTACT_INFO: "/students/profile/contact-info",
  CHANGE_PASSWORD: "/students/password/change",
  ONBOARDING: "/students/onboarding",
  CHANGE_EMAIL: "/students/update-email",
  CLOSE_ACCOUNT: "/students/close-account",
  AUTHENTICATE_WITH_GOOGLE: "/auth/login/google",
  GET_STUDENT_PROFILE: (id: string) => "/admin/student/profile/" + id,
  GET_SCHOOL_BY_SLUG: (slug: string) => "/school/" + slug,
  GET_SCHOOL_COURSES_BY_SLUG: (schoolSlug: string) =>
    "/school/" + schoolSlug + "/courses",
  GET_COURSE_BY_SLUG: (courseSlug: string) => "/courses/" + courseSlug,
  GET_COHORTS_BY_COURSE_ID: (courseId: string) =>
    `/courses/admin/${courseId}/cohorts`,
  GET_COHORT_BY_ID: (cohortId: string) => `/cohorts/${cohortId}`,
  GET_SCHOOLS: "/school",
  LOGIN: "/auth/login/admin",
  GET_ADMINS: "/admin",
  GET_ADMIN_ID: (adminId: string) => `/admin/${adminId}`,
  TOGGLE_SCHOOL_STATUS: (schoolId: string) =>
    `/school/admin/${schoolId}/toggle`,
  DELETE_SCHOOL_BY_ID: (schoolId: string) => `/school/admin/delete/${schoolId}`,
  DELETE_COURSE_BY_ID: (courseId: string) =>
    `/courses/admin/delete/${courseId}`,
  GET_DASHBOARD_DATA: "/admin-home-dashboard",
  GET_TUTOR_BY_ID: (id: string | number) => `/admin/tutors/${id}`,
  ADD_TUTOR: "/admin/tutors",
};

export default endpoints;
