import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./BlogCreatedContainer.module.css";
import BlogMangementModulesEmptyTab from "../BlogManagementModules/BlogMangementModulesEmptyTab";
import BlogPostCard from "../../../Components/BlogPostCard/BlogPostCard";
import AcceptedModal from "../../../Components/Modals/AcceptedModal/AcceptedModal";
import CancelSchoolSuccessfulModal from "../../SchoolManagementPagesContainer/CreateSchoolPreview/PreviewModals/CancelSchoolSuccessfulModal";
import SchoolCreatedSuccessfulModal from "../../SchoolManagementPagesContainer/CreateSchoolPreview/PreviewModals/SchoolCreatedSuccessfulModal";
import publishImg from "../../../Assets/Images/activateSchool.svg";
import restoreImg from "../../../Assets/Images/draftImg.svg";
import archivehImg from "../../../Assets/Images/archivehImg.svg";
import { BlogsContext } from "../../../Context/BlogsContext";
import CancelSchoolCreationModal from "../../SchoolManagementPagesContainer/CreateSchoolPreview/PreviewModals/CancelSchoolCreationModal";
import deleteSvg from "../../../Assets/Images/deleteBlogPost.svg";
import { useNavigate } from "react-router-dom";
import ReportBlogPostModal from "../../../Components/BlogPostCard/Modals/ReportBlogPostModal/ReportBlogPostModal";
import ShareBlogPostModal from "../../../Components/BlogPostCard/Modals/ShareBlogPostModal/ShareBlogPostModal";
import { mutate } from "swr";
import { backend_url } from "../../../Utilities/global";

type BlogCreatedContainerProps = {
  blogPost: any[];
  notShowEmptyTab?: boolean;
  blogCategories: any;
  activeFilter: any;
};

const BlogCreatedContainer = ({
  blogPost,
  notShowEmptyTab,
  blogCategories,
  activeFilter,
}: BlogCreatedContainerProps) => {
  // Context
  const {
    requestState,
    sendPostToDrafts,
    sendPostToArchive,
    sendPostToPublished,
  } = useContext(BlogsContext);

  //   States
  const [searchTerm, setSearchTerm] = useState("");
  const [displayDeleteBlogPostModal, setDisplayDeleteBlogPostModal] =
    useState(false);
  const [displayRestoreBlogPostModal, setDisplayRestoreBlogPostModal] =
    useState(false);
  const [
    displayRestoreBlogPostSuccessfulModal,
    setDisplayRestoreBlogPostSuccessfulModal,
  ] = useState(false);
  const [displayPublishBlogPostModal, setDisplayPublishBlogPostModal] =
    useState(false);
  const [
    displayPublishBlogPostSuccessfulModal,
    setDisplayPublishBlogPostSuccessfulModal,
  ] = useState(false);
  const [displayArchiveBlogPostModal, setDisplayArchiveBlogPostModal] =
    useState(false);
  const [
    displayArchiveBlogPostSuccessfulModal,
    setDisplayArchiveBlogPostSuccessfulModal,
  ] = useState(false);
  const [displayDraftBlogPostModal, setDisplayDraftBlogPostModal] =
    useState(false);
  const [
    displayDraftBlogPostSuccessfulModal,
    setDisplayDraftBlogPostSuccessfulModal,
  ] = useState(false);
  const [displayReportBlogPostModal, setDisplayReportBlogPostModal] =
    useState(false);
  const [displayShareBlogPostModal, setDisplayShareBlogPostModal] =
    useState(false);
  const [activeId, setActiveId] = useState<number | null>(null);

  // Router
  const navigate = useNavigate();

  useEffect(() => {
    if (requestState?.data) {
      if (requestState.id === "send-to-drafts") {
        setDisplayDraftBlogPostModal(false);
        setDisplayRestoreBlogPostModal(false);
        setDisplayDraftBlogPostSuccessfulModal(true);
      }

      if (requestState.id === "send-to-archive") {
        setDisplayArchiveBlogPostModal(false);
        setDisplayArchiveBlogPostSuccessfulModal(true);
      }

      if (requestState.id === "send-to-published") {
        setDisplayPublishBlogPostModal(false);
        setDisplayPublishBlogPostSuccessfulModal(true);
      }

      mutate(
        `${backend_url}/api/ise/v1/admin/blog/post/status?status=${
          activeFilter as string
        }`
      );
    }
  }, [requestState?.data]);

  if (!notShowEmptyTab && blogPost.length === 0) {
    return (
      <BlogMangementModulesEmptyTab
        header="No blogPost available"
        paragraph="Select “Create blogpost” to share insights, and connect with iṣẹ́’s audience. Once you publish a blogpost, it’ll appear here."
      />
    );
  }

  return (
    <>
      {displayDeleteBlogPostModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayDeleteBlogPostModal(false);
          }}
          body={
            <CancelSchoolCreationModal
              imgSrc={deleteSvg}
              button1="Cancel"
              button2="Yes, Delete"
              header="Delete blogpost?"
              paragraph="This is a permanent action, All likes, views and comments will be lost."
              onClick={() => {
                setDisplayDeleteBlogPostModal(false);
                navigate("/blogs");
              }}
              onClick2={() => {
                setDisplayDeleteBlogPostModal(false);
              }}
            />
          }
        />
      )}

      {displayRestoreBlogPostModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayRestoreBlogPostModal(false);
          }}
          body={
            <SchoolCreatedSuccessfulModal
              imgSrc={restoreImg}
              buttonText2="Cancel"
              header="Restore blogpost?"
              buttonText="Yes, restore blogpost"
              paragraph="Restoring a blog post will move it to drafts on the dashboard"
              onClick={() => {
                setDisplayRestoreBlogPostModal(false);
                navigate("");
              }}
              onClick2={() => {
                sendPostToDrafts(String(activeId));
              }}
              loading={requestState?.isLoading}
            />
          }
        />
      )}
      {displayRestoreBlogPostSuccessfulModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayRestoreBlogPostSuccessfulModal(false);
          }}
          body={
            <CancelSchoolSuccessfulModal
              buttonText="Done"
              header="Blogpost in drafts"
              onClick={() => {
                setDisplayRestoreBlogPostSuccessfulModal(false);
              }}
            />
          }
        />
      )}

      {displayPublishBlogPostModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayPublishBlogPostModal(false);
          }}
          body={
            <SchoolCreatedSuccessfulModal
              imgSrc={publishImg}
              buttonText2="Cancel"
              header="Publish blogpost?"
              buttonText="Publish blogpost"
              paragraph="Once you publish, the blogpost will be live on iṣẹ́ blog"
              onClick={() => {
                setDisplayPublishBlogPostModal(false);
                navigate("");
              }}
              onClick2={() => {
                sendPostToPublished(String(activeId));
              }}
              loading={requestState?.isLoading}
            />
          }
        />
      )}

      {displayPublishBlogPostSuccessfulModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayPublishBlogPostSuccessfulModal(false);
          }}
          body={
            <CancelSchoolSuccessfulModal
              buttonText="Close"
              header="Blogpost published successfully!"
              paragraph="Track blogpost performance on your dashboard."
              onClick={() => {
                setDisplayPublishBlogPostSuccessfulModal(false);
              }}
            />
          }
        />
      )}

      {displayArchiveBlogPostModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayArchiveBlogPostModal(false);
          }}
          body={
            <SchoolCreatedSuccessfulModal
              imgSrc={archivehImg}
              buttonText2="Cancel"
              header="Archive blogpost?"
              buttonText="Yes, archive blogpost"
              paragraph="Archiving will remove this post from the website but keep it saved for future reference."
              onClick={() => {
                setDisplayArchiveBlogPostModal(false);
                navigate("");
              }}
              onClick2={() => {
                sendPostToArchive(String(activeId));
              }}
              loading={requestState?.isLoading}
            />
          }
        />
      )}

      {displayArchiveBlogPostSuccessfulModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayArchiveBlogPostSuccessfulModal(false);
          }}
          body={
            <CancelSchoolSuccessfulModal
              buttonText="Done"
              header="Blogpost archived successfully!"
              onClick={() => {
                setDisplayArchiveBlogPostSuccessfulModal(false);
                navigate("");
              }}
            />
          }
        />
      )}

      {displayDraftBlogPostModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayDraftBlogPostModal(false);
          }}
          body={
            <SchoolCreatedSuccessfulModal
              imgSrc={restoreImg}
              buttonText2="Cancel"
              header="Send to draft?"
              buttonText="Yes, send to draft"
              paragraph="This action will disable the blogpost temporarily.  Blogpost likes, views and comments will be lost."
              onClick={() => {
                setDisplayDraftBlogPostModal(false);
              }}
              onClick2={() => {
                sendPostToDrafts(String(activeId));
              }}
              loading={requestState?.isLoading}
            />
          }
        />
      )}

      {displayDraftBlogPostSuccessfulModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayDraftBlogPostSuccessfulModal(false);
          }}
          body={
            <CancelSchoolSuccessfulModal
              buttonText="Done"
              header="Blogpost in drafts"
              onClick={() => {
                setDisplayDraftBlogPostSuccessfulModal(false);
              }}
            />
          }
        />
      )}

      {displayReportBlogPostModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayReportBlogPostModal(false);
          }}
          body={
            <ReportBlogPostModal
              onClick={() => {
                setDisplayReportBlogPostModal(false);
              }}
              activeId={activeId as number}
            />
          }
        />
      )}

      {displayShareBlogPostModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayShareBlogPostModal(false);
          }}
          body={
            <ShareBlogPostModal
              onClick={() => {
                setDisplayShareBlogPostModal(false);
              }}
              activeId={String(activeId) as string}
            />
          }
        />
      )}

      <div className={classes.body}>
        <div className={classes.header}>
          <span>Filter by:</span>
          <button type="button">Category</button>
          <div className={classes.inputSection}>
            <input
              type="text"
              placeholder="Search by name"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              value={searchTerm}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                stroke="#2E2E2E"
                strokeWidth="2"
                stroke-linecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className={classes.container}>
          {blogPost
            ?.filter((data: any) =>
              data?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
            )
            ?.map((data: any, i: number) => (
              <BlogPostCard
                key={i}
                id={data?.id}
                image={data?.cover_image}
                title={data?.title}
                date={data?.created_at}
                category={
                  blogCategories?.find(
                    (category: any) => category?.id === data?.categoryId
                  )?.title
                }
                postStatus={data?.status}
                setActiveId={setActiveId}
                setDisplayShareBlogPostModal={setDisplayShareBlogPostModal}
                setDisplayReportBlogPostModal={setDisplayReportBlogPostModal}
                setDisplayDraftBlogPostModal={setDisplayDraftBlogPostModal}
                setDisplayPublishBlogPostModal={setDisplayPublishBlogPostModal}
                setDisplayArchiveBlogPostModal={setDisplayArchiveBlogPostModal}
                setDisplayRestoreBlogPostModal={setDisplayRestoreBlogPostModal}
                setDisplayDeleteBlogPostModal={setDisplayDeleteBlogPostModal}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default BlogCreatedContainer;
