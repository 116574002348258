import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SchoolCreatingLayout from "../../../Components/SchoolCreatingLayout/SchoolCreatingLayout";
import classes from "../CreateBlogAddDetails/CreateBlogAddDetails.module.css";
import Input from "../../../Components/Input/Input";
import Button from "../../../Components/Button/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import CreateBlogCategoryModal from "./CreateBlogCategoryModal";
import DragAndDropInput from "../../../Components/DragAndDropInput/DragAndDropInput";
import DropdownWithSearch from "../../../Components/DropdownWithSearch/DropdownWithSearch";
import AcceptedModal from "../../../Components/Modals/AcceptedModal/AcceptedModal";
import cancelSvg from "../../../Assets/Images/CancelSchoolCreationImage.svg";
import CancelSchoolCreationModal from "../../SchoolManagementPagesContainer/CreateSchoolPreview/PreviewModals/CancelSchoolCreationModal";
import CancelSchoolSuccessfulModal from "../../SchoolManagementPagesContainer/CreateSchoolPreview/PreviewModals/CancelSchoolSuccessfulModal";
import close from "../../../Assets/Images/x-sign.svg";
import {
  createBlogpostDataType,
  createCategoryDataType,
} from "../../../Types/requestTypes";
import { useGetBlogCategories } from "../../../Hooks/useBlog";
import Loader from "../../../Components/Loader/Loader";
import { inputChangeHandler } from "../../../HelperFunctions/inputChangeHandler";
import { calculateReadingTime } from "../../../HelperFunctions/calclateReadingTime";
import { BlogsContext } from "../../../Context/BlogsContext";
import { mutate } from "swr";
import { backend_url } from "../../../Utilities/global";

type CreateBlogUploadFileProp = {
  editInformation?: boolean;
  addTag?: string[];
  category?: string;
  image?: string;
  createBlogPostData?: createBlogpostDataType;
  setCreateBlogPostData?: Dispatch<SetStateAction<createBlogpostDataType>>;
  blogCategories?: any;
  selectedCategory?: string;
  setSelectedCategory?: Dispatch<SetStateAction<string>>;
};

const CreateBlogUploadFile = ({
  image,
  category,
  editInformation,
  createBlogPostData,
  setCreateBlogPostData,
  blogCategories,
  selectedCategory,
  setSelectedCategory,
}: CreateBlogUploadFileProp) => {
  // Router
  const navigate = useNavigate();

  // Context
  const { requestState, createBlogCategory } = useContext(BlogsContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setSearchParams] = useSearchParams();
  const [newImage, setNewImage] = useState("");
  const [
    displayCancelSchoolCreationModal,
    setDisplayCancelSchoolCreationModal,
  ] = useState(false);
  const [
    displayCancelSchoolSuccessfulModal,
    setDisplayCancelSchoolSuccessfulModal,
  ] = useState(false);
  const [displayCreateBlogCategoryModal, setDisplayCreateBlogCategoryModal] =
    useState(false);
  const [
    displayCreateBlogCategorySuccessfulModal,
    setDisplayCreateBlogCategorySuccessfulModal,
  ] = useState(false);
  const [categoryData, setCategoryData] = useState<createCategoryDataType>({
    title: "",
  });
  const [tag, setTag] = useState("");

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file: File = (event.target.files as FileList)[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result as string;
        if (setCreateBlogPostData) {
          setCreateBlogPostData((prevState) => {
            return {
              ...prevState,
              cover_image: { frontendFile: imageDataUrl, file },
            };
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Effects
  useEffect(() => {
    if (setCreateBlogPostData && createBlogPostData?.content) {
      setCreateBlogPostData((prevState: any) => {
        return {
          ...prevState,
          reading_minutes: calculateReadingTime(createBlogPostData?.content),
        };
      });
    }

    if (selectedCategory && setCreateBlogPostData && blogCategories) {
      const selectedCategoryId = blogCategories?.find((data: any) => {
        return data?.title === selectedCategory;
      })?.id;

      setCreateBlogPostData((prevState: createBlogpostDataType) => {
        return { ...prevState, categoryId: selectedCategoryId };
      });
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (requestState?.data) {
      if (requestState.id === "create-blog-category") {
        setDisplayCreateBlogCategoryModal(false);
        setDisplayCreateBlogCategorySuccessfulModal(true);
      }

      mutate(`${backend_url}/api/ise/v1/admin/blog/category`);
    }
  }, [requestState?.data]);

  return (
    <SchoolCreatingLayout steps={[1, 2, 3]}>
      {displayCancelSchoolCreationModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayCancelSchoolCreationModal(false);
          }}
          body={
            <CancelSchoolCreationModal
              imgSrc={cancelSvg}
              button1="Save as draft"
              button2="Cancel blogpost"
              header="Cancel blogpost creation?"
              paragraph="This is a permanent action you will lose the details you’ve added. You might want to save as draft instead"
              onClick={() => {
                setDisplayCancelSchoolCreationModal(false);
                navigate("/blogs");
              }}
              onClick2={() => {
                setDisplayCancelSchoolCreationModal(false);
                setDisplayCancelSchoolSuccessfulModal(true);
              }}
            />
          }
        />
      )}
      {displayCancelSchoolSuccessfulModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayCancelSchoolSuccessfulModal(false);
          }}
          body={
            <CancelSchoolSuccessfulModal
              buttonText="Create blogpost"
              header="Blogpost creation canceled"
              paragraph="Select ‘Create blogpost’ to start all over."
              onClick={() => {
                setDisplayCancelSchoolSuccessfulModal(false);
                navigate("/blogs/add-post?step=1");
              }}
            />
          }
        />
      )}
      {displayCreateBlogCategoryModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayCreateBlogCategoryModal(false);
          }}
          body={
            <CreateBlogCategoryModal
              onClick={() => {
                setDisplayCreateBlogCategoryModal(false);
              }}
              onClick2={() => {
                createBlogCategory(categoryData);
              }}
              createCategoryData={categoryData}
              setCreateCategoryData={setCategoryData}
              requestState={requestState}
            />
          }
        />
      )}
      {displayCreateBlogCategorySuccessfulModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayCreateBlogCategorySuccessfulModal(false);
          }}
          body={
            <CancelSchoolSuccessfulModal
              buttonText="Done"
              header="Category created"
              onClick={() => {
                setDisplayCreateBlogCategorySuccessfulModal(false);
              }}
            />
          }
        />
      )}
      <section className={classes.container}>
        <h2>Add blogpost details</h2>
        <p>Organise blogpost content with visuals, tags and categories</p>
        {!editInformation && (
          <>
            <DragAndDropInput
              labelText="Upload cover image * (Clear, useful cover images grab users attention)"
              acceptedFileTypes=".png"
              performFIleValidation={true}
              value={createBlogPostData?.cover_image.file}
              onChange={handleFileInputChange}
            />
            <ul className={classes.fileUploadInfo}>
              <li>
                You can upload: <strong> .png</strong> files{" "}
              </li>
              <li>
                {" "}
                File size: <strong>1MB</strong> with maximum width and height of
                750 X 600px
              </li>
            </ul>
          </>
        )}

        {editInformation && (
          <div className={classes.uploadededFile}>
            <img
              src={createBlogPostData?.cover_image?.frontendFile}
              alt={category}
            />
            <label htmlFor="replaceImg">Replace Image</label>
            <input
              type="file"
              name=""
              accept=".png"
              id="replaceImg"
              placeholder=""
              onChange={handleFileInputChange}
            />
          </div>
        )}

        <div>
          <DropdownWithSearch
            options={blogCategories?.map((data: any) => {
              return data?.title;
            })}
            title="Select option"
            selected={
              selectedCategory ||
              blogCategories?.find(
                (data: any) => data?.id === createBlogPostData?.categoryId
              )?.title
            }
            label="Select blogpost category *"
            tip="Categories help readers filter and find  blogpost easily "
            setSelected={setSelectedCategory}
          />

          <div className={classes.flexButtonSection}>
            <Button
              type="null"
              onClick={() => {
                setDisplayCreateBlogCategoryModal(true);
              }}
            >
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1.5V13.5M13 7.5L1 7.5"
                  stroke="#664EFE"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>Add category</span>
            </Button>
          </div>

          <Input
            tip="You can only add 3 tags"
            label="Add Tags (Use a comma to separate tags) *"
            placeholder="E.g Technology, Edtech, Carrer tips"
            onKeyup={(e) => {
              if (createBlogPostData && createBlogPostData?.tags.length <= 3) {
                if (e.key === "Enter" && setCreateBlogPostData) {
                  setCreateBlogPostData((prevState) => {
                    const updatedState = { ...prevState };
                    updatedState.tags = [...updatedState.tags, tag];
                    return updatedState;
                  });

                  setTag("");
                }
              }
            }}
            value={tag}
            onChange={(e) => inputChangeHandler(e, setTag, true)}
            readOnly={createBlogPostData && createBlogPostData?.tags.length > 2}
          />

          <div className={`${classes.tag} ${classes.addTag}`}>
            {createBlogPostData?.tags.map((item, index) => (
              <p key={index}>
                <span>{item}</span>
                <img
                  src={close}
                  alt="remove"
                  onClick={() => {
                    if (setCreateBlogPostData) {
                      setCreateBlogPostData((prevState) => {
                        const updatedState = { ...prevState };
                        const filteredUpdates = updatedState.tags.filter(
                          (data) => data !== item
                        );
                        updatedState.tags = filteredUpdates;
                        return updatedState;
                      });
                    }
                  }}
                />
              </p>
            ))}
          </div>

          <Input
            label="Select blogpost reading minutes *"
            tip="This is the average number of minutes it would take for audience to read this blogpost"
            value={String(createBlogPostData?.reading_minutes)}
            readOnly
          />
        </div>

        <div
          className={`${classes.buttonSection} ${classes.buttonSectionThree}`}
        >
          <Button
            type="null"
            className={classes.canelButton}
            onClick={() => {
              setDisplayCancelSchoolCreationModal(true);
            }}
          >
            <span>Cancel</span>
          </Button>
          <Button
            type="secondary"
            onClick={() => {
              setSearchParams({ step: "1" });
            }}
          >
            <span>Back</span>
          </Button>
          <Button
            disabled={
              !createBlogPostData?.cover_image ||
              !createBlogPostData?.categoryId ||
              createBlogPostData?.tags.length < 1
            }
            onClick={() => {
              setSearchParams({ step: "3" });
            }}
          >
            <span>Preview blogpost</span>
          </Button>
        </div>
      </section>
    </SchoolCreatingLayout>
  );
};

export default CreateBlogUploadFile;
