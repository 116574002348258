import React, { useContext, useEffect, useState } from "react";
import Layout from "../Components/Layout/Layout";
import { AppContext } from "../Context/AppContext";
import { useLocation, useParams } from "react-router-dom";
import CreateBlogAddDetails from "../Containers/BlogManagementPageContainer/CreateBlogAddDetails/CreateBlogAddDetails";
import CreateBlogUploadFile from "../Containers/BlogManagementPageContainer/CreateBlogUploadFile/CreateBlogUploadFile";
import BlogManagementContainer from "../Containers/BlogManagementPageContainer/BlogManagementContainer/BlogManagementContainer";
import CreateBlogPreview from "../Containers/BlogManagementPageContainer/CreateBlogPreview/CreateBlogPreview";
import {
  useBlogPosts,
  useBlogPostsById,
  useGetBlogCategories,
} from "../Hooks/useBlog";
import Loader from "../Components/Loader/Loader";
import { createBlogpostDataType } from "../Types/requestTypes";

const EditBlogPostPage = () => {
  // Router
  const location = useLocation();
  const { postId } = useParams();
  const userStep = new URLSearchParams(location.search).get("step");

  //   Formdata
  const [createBlogPostDataFormData, setCreateBlogPostDataFormData] = useState(
    new FormData()
  );

  //   Requests
  const { isLoading, data } = useBlogPostsById(postId as string);
  const { isLoading: blogCategoriesIsLoading, data: blogCategoriesData } =
    useGetBlogCategories();
  const blogPost = data?.data;
  const blogCategories = blogCategoriesData?.data;

  //   States
  const [postData, setPostData] = useState<createBlogpostDataType>({
    title: "",
    content: "",
    cover_image: { frontendFile: "", file: null },
    tags: [],
    reading_minutes: 0,
    categoryId: null,
    status: "published",
  });
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    const formData = new FormData();

    formData.append("title", postData?.title);
    formData.append("content", postData?.content);
    formData.append("cover_image", postData?.cover_image.file as File);
    formData.append("tags", postData?.tags.toString());
    formData.append("reading_minutes", String(postData?.reading_minutes));
    formData.append("categoryId", String(postData?.categoryId));
    formData.append("status", postData?.status);

    setCreateBlogPostDataFormData(formData);
  }, [postData]);

  //   Utils
  const activeCategoryTitle = blogCategories?.find((data: any) => {
    return data?.title === selectedCategory;
  })?.title;

  useEffect(() => {
    if (blogPost) {
      setPostData({
        title: blogPost?.title,
        content: blogPost?.content,
        cover_image: { frontendFile: blogPost?.cover_image, file: null },
        tags: blogPost?.tags?.split(","),
        reading_minutes: blogPost?.reading_minutes,
        categoryId: 1,
        status: blogPost?.status,
      });
    }
  }, [blogPost]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout>
      {userStep === "1" ? (
        <CreateBlogAddDetails
          createBlogPostData={postData}
          setCreateBlogPostData={setPostData}
        />
      ) : userStep === "2" ? (
        <CreateBlogUploadFile
          editInformation={true}
          createBlogPostData={postData}
          setCreateBlogPostData={setPostData}
          blogCategories={blogCategories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      ) : userStep === "3" ? (
        <CreateBlogPreview
          title={postData?.title}
          image={postData?.cover_image.frontendFile}
          content={postData?.content}
          category={activeCategoryTitle || ""}
          addTag={postData?.tags}
          readTime={String(postData?.reading_minutes)}
          createBlogPostDataFormData={createBlogPostDataFormData}
          setCreateBlogPostData={setPostData}
          updateInformation="Update blogpost"
        />
      ) : (
        <BlogManagementContainer />
      )}
    </Layout>
  );
};

export default EditBlogPostPage;
