const Pagination = (props: {
  setPageNumber: (value: number) => void;
  pageNumber: number;
  lastPage: number;
}) => {
  const isFirstPage = props.pageNumber <= 1;
  const isLastPage = props.pageNumber === props.lastPage;

  return (
    <div className="bg-white flex gap-x-6 items-center justify-center border-t py-4 rounded-b-[10px]">
      <button
        className={`${
          isFirstPage ? "bg-gray-100 cursor-not-allowed" : ""
        } border border-[#dfe2e5] !h-[40px] !w-[40px] !px-0 flex items-center justify-center rounded-full`}
        disabled={isFirstPage}
        onClick={() => props.setPageNumber(props.pageNumber - 1)}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className="rotate-180"
        >
          <g opacity="0.38">
            <path
              d="M9 5L16 12L9 19"
              stroke="#B9B9B9"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </button>

      <div className="w-[48px] h-[48px] bg-[#664EFE] rounded-full text-white flex items-center justify-center font-medium">
        {props.pageNumber}
      </div>

      <button
        className={`${
          isLastPage ? "bg-gray-100 cursor-not-allowed" : ""
        } border border-[#dfe2e5] !h-[40px] !w-[40px] !px-0 flex items-center justify-center rounded-full`}
        disabled={isLastPage}
        onClick={() => props.setPageNumber(props.pageNumber + 1)}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g opacity="0.38">
            <path
              d="M9 5L16 12L9 19"
              stroke="#B9B9B9"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </button>
    </div>
  );
};

export default Pagination;
