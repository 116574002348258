import { useEffect, useState } from "react";

interface ITabs {
  children: any;
  value: string | number;
  onChange: (identifier: string | number) => void;
}

const Tabs = ({ children, value, onChange, ...rest }: ITabs) => {
  const [activeTab, setActiveTab] = useState<string | number>("");

  useEffect(() => {
    setActiveTab(value);
  }, []);

  const handleChange = (identifier: string | number) => {
    onChange(identifier);
    setActiveTab(identifier);
  };

  return (
    <div {...rest}>
      {children?.map((child: any) => ({
        ...child,
        props: { ...child.props, onChange: handleChange, activeTab },
      }))}
    </div>
  );
};

export default Tabs;
