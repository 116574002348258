import { ReactNode } from "react";
import Modal from "./Modal";

import GIF_SUCCESS from "Assets/Gifs/success.gif";

interface IProps {
  showModal: boolean;
  title: string;
  message: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
  children?: ReactNode;
}

const Success = (props: IProps) => {
  const {
    showModal,
    title,
    message,
    children,
    primaryButtonText,
    secondaryButtonText,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
  } = props;

  return (
    <Modal showModal={showModal}>
      <div className="bg-white w-[90%] lg:w-[876px] rounded-[10px] pb-14">
        <div className="h-[280px] w-[280px] mx-auto">
          <img
            src={GIF_SUCCESS}
            alt="success_gif"
            className="h-full w-full object-cover"
          />
        </div>

        <h2 className="text-[25px] font-medium text-center">{title}</h2>

        <p className="text-center max-w-[425px] mx-auto text-[#737373] mt-4">
          {message}
        </p>

        {children ? (
          children
        ) : (
          <div className="flex justify-center mt-8 gap-x-6">
            {secondaryButtonText && (
              <button
                className="border-2 border-[#664EFE] text-[#664EFE] h-[46px] px-6 rounded-[5px] font-medium"
                onClick={onSecondaryButtonClick && onSecondaryButtonClick}
              >
                {secondaryButtonText}
              </button>
            )}

            <button
              className="bg-[#664EFE] border-[#664EFE] text-white h-[46px] px-6 rounded-[5px] font-medium"
              onClick={onPrimaryButtonClick}
            >
              {primaryButtonText}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Success;
