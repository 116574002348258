import { useState } from "react";
import {
  TrashIcon,
  EyeIcon,
  PlusIcon,
  PencilIcon,
  Bars4Icon,
} from "@heroicons/react/24/outline";
import classes from "./ActionsModal.module.css";

type ActionsModalProps = {
  onClick: () => void;
  onClick2: () => void;
  onClick3: () => void;
  onClick4: () => void;
  onClick5: () => void;
  onClick6: () => void;
  onClick7: () => void;
  schoolIsActive?: boolean;
};

const ActionsModal = ({
  onClick,
  onClick2,
  onClick3,
  onClick4,
  onClick5,
  onClick6,
  onClick7,
  schoolIsActive,
}: ActionsModalProps) => {
  const [modalOptions, setModalOptions] = useState([
    {
      action: onClick,
      title: "View school",
      svg: <Bars4Icon className="size-4 text-[#2E2E2E]" />,
      isActive: true,
    },
    {
      action: onClick2,
      title: "Edit school",
      svg: <PencilIcon className="size-4 text-[#2E2E2E]" />,
      isActive: true,
    },
    {
      action: onClick3,
      title: "Add course",
      svg: <PlusIcon className="size-4 text-[#2E2E2E]" />,
      isActive: true,
    },
    {
      action: onClick4,
      title: "View course",
      svg: <EyeIcon className="size-4 text-[#2E2E2E]" />,
      isActive: true,
    },

    {
      action: onClick5,
      title: "Delete school",
      svg: <TrashIcon className="size-4 text-[#2E2E2E]" />,
      isActive: true,
    },

    {
      action: onClick6,
      title: "Activate school",
      svg: (
        <svg
          width="24"
          height="14"
          viewBox="0 0 24 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.99978 13.1851C5.28163 13.1851 3.8212 12.5837 2.6185 11.381C1.4158 10.1782 0.814453 8.71768 0.814453 6.99943C0.814453 5.28118 1.4158 3.82079 2.6185 2.61825C3.8212 1.41572 5.28163 0.814453 6.99978 0.814453H16.9998C18.7179 0.814453 20.1784 1.41584 21.3811 2.6186C22.5838 3.82137 23.1851 5.28188 23.1851 7.00013C23.1851 8.71838 22.5838 10.1788 21.3811 11.3813C20.1784 12.5838 18.7179 13.1851 16.9998 13.1851H6.99978ZM6.99978 10.9998H16.9998C18.0998 10.9998 19.0414 10.6081 19.8248 9.82478C20.6081 9.04145 20.9998 8.09978 20.9998 6.99978C20.9998 5.89978 20.6081 4.95811 19.8248 4.17478C19.0414 3.39145 18.0998 2.99978 16.9998 2.99978H6.99978C5.89978 2.99978 4.95811 3.39145 4.17478 4.17478C3.39144 4.95811 2.99978 5.89978 2.99978 6.99978C2.99978 8.09978 3.39144 9.04145 4.17478 9.82478C4.95811 10.6081 5.89978 10.9998 6.99978 10.9998ZM6.99978 9.99978C7.83311 9.99978 8.54144 9.70811 9.12478 9.12478C9.70811 8.54145 9.99978 7.83311 9.99978 6.99978C9.99978 6.16645 9.70811 5.45811 9.12478 4.87478C8.54144 4.29145 7.83311 3.99978 6.99978 3.99978C6.16644 3.99978 5.45811 4.29145 4.87478 4.87478C4.29144 5.45811 3.99978 6.16645 3.99978 6.99978C3.99978 7.83311 4.29144 8.54145 4.87478 9.12478C5.45811 9.70811 6.16644 9.99978 6.99978 9.99978Z"
            fill="black"
          />
        </svg>
      ),
      isActive: !schoolIsActive,
    },

    {
      action: onClick7,
      title: "Deactivate school",
      svg: (
        <svg
          width="24"
          height="14"
          viewBox="0 0 24 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.99978 13.1851C5.28163 13.1851 3.8212 12.5837 2.6185 11.381C1.4158 10.1782 0.814453 8.71768 0.814453 6.99943C0.814453 5.28118 1.4158 3.82079 2.6185 2.61825C3.8212 1.41572 5.28163 0.814453 6.99978 0.814453H16.9998C18.7179 0.814453 20.1784 1.41584 21.3811 2.6186C22.5838 3.82137 23.1851 5.28188 23.1851 7.00013C23.1851 8.71838 22.5838 10.1788 21.3811 11.3813C20.1784 12.5838 18.7179 13.1851 16.9998 13.1851H6.99978ZM6.99978 10.9998H16.9998C18.0998 10.9998 19.0414 10.6081 19.8248 9.82478C20.6081 9.04145 20.9998 8.09978 20.9998 6.99978C20.9998 5.89978 20.6081 4.95811 19.8248 4.17478C19.0414 3.39145 18.0998 2.99978 16.9998 2.99978H6.99978C5.89978 2.99978 4.95811 3.39145 4.17478 4.17478C3.39144 4.95811 2.99978 5.89978 2.99978 6.99978C2.99978 8.09978 3.39144 9.04145 4.17478 9.82478C4.95811 10.6081 5.89978 10.9998 6.99978 10.9998ZM6.99978 9.99978C7.83311 9.99978 8.54144 9.70811 9.12478 9.12478C9.70811 8.54145 9.99978 7.83311 9.99978 6.99978C9.99978 6.16645 9.70811 5.45811 9.12478 4.87478C8.54144 4.29145 7.83311 3.99978 6.99978 3.99978C6.16644 3.99978 5.45811 4.29145 4.87478 4.87478C4.29144 5.45811 3.99978 6.16645 3.99978 6.99978C3.99978 7.83311 4.29144 8.54145 4.87478 9.12478C5.45811 9.70811 6.16644 9.99978 6.99978 9.99978Z"
            fill="#DC362E"
          />
        </svg>
      ),
      isActive: schoolIsActive,
    },
  ]);

  return (
    <div className={classes.container}>
      {modalOptions
        ?.filter((data) => data.isActive)
        .map((data, i) => (
          <>
            <div key={i} onClick={data.action} className="flex items-center">
              {data.svg}
              <span
                style={
                  data?.title === "Deactivate school"
                    ? { color: "#DC362E" }
                    : { color: undefined }
                }
              >
                {data.title}
              </span>
            </div>
          </>
        ))}
    </div>
  );
};

export default ActionsModal;
