import { createContext, useContext, useState } from "react";
import { requestHandler2 } from "../HelperFunctions/requestHandler";
import { createCategoryDataType } from "../Types/requestTypes";
import { backend_url } from "../Utilities/global";
import { AppContext } from "./AppContext";
import { requestType } from "./AuthUserContext";

export type BlogsContextvalues = {
  requestState: requestType;
  createBlogCategory: (data: createCategoryDataType) => void;
  createBlogPost: (data: FormData) => void;
  updateBlogPost: (id: string, data: FormData) => void;
  sendPostToDrafts: (id: string) => void;
  sendPostToArchive: (id: string) => void;
  sendPostToPublished: (id: string) => void;
  editBlogCategory: (id: string, data: createCategoryDataType) => void;
  deleteBlogCategory: (id: string) => void;
};

export type BlogsContextProviderTypes = {
  children: React.ReactNode;
};

export const BlogsContext = createContext({} as BlogsContextvalues);

const BlogsContextProvider = ({ children }: BlogsContextProviderTypes) => {
  // COntext
  const { setNotifications } = useContext(AppContext);

  // States
  const [requestState, setRequestState] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  //   Requests
  const createBlogCategory = (data: createCategoryDataType) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/admin/blog/category`,
      method: "POST",
      data,
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      successMessage: "Blog category created successfully",
      requestCleanup: true,
      requestId: "create-blog-category",
    });
  };

  const editBlogCategory = (id: string, data: createCategoryDataType) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/admin/blog/category/${id}`,
      method: "PUT",
      data,
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      successMessage: "Blog category edited successfully",
      requestCleanup: true,
      requestId: "edit-blog-category",
    });
  };

  const deleteBlogCategory = (id: string) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/admin/blog/category/${id}`,
      method: "DELETE",
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      successMessage: "Blog category deleted successfully",
      requestCleanup: true,
      requestId: "delete-blog-category",
    });
  };

  const createBlogPost = (data: FormData) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/admin/blog/posts`,
      method: "POST",
      data,
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      successMessage: "Blog category created successfully",
      requestCleanup: true,
      requestId: "create-blog-post",
      isMultipart: true,
    });
  };

  const updateBlogPost = (id: string, data: FormData) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/admin/blog/post/${id}`,
      method: "PUT",
      data,
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      successMessage: "Blog category created successfully",
      requestCleanup: true,
      requestId: "create-blog-post",
      isMultipart: true,
    });
  };

  const sendPostToDrafts = (id: string) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/admin/blog/post/${id}/revert-to-draft`,
      method: "PATCH",
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      successMessage: "Blog post saved to drafts successfully",
      requestCleanup: true,
      requestId: "send-to-drafts",
    });
  };

  const sendPostToArchive = (id: string) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/admin/blog/post/archive/${id}`,
      method: "PATCH",
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      successMessage: "Blog post saved to drafts successfully",
      requestCleanup: true,
      requestId: "send-to-archive",
    });
  };

  const sendPostToPublished = (id: string) => {
    requestHandler2({
      url: `${backend_url}/api/ise/v1/admin/blog/post/${id}/publish`,
      method: "PATCH",
      state: requestState,
      setState: setRequestState,
      setNotifications,
      setNotificationsFailure: true,
      successMessage: "Blog post saved to drafts successfully",
      requestCleanup: true,
      requestId: "send-to-published",
    });
  };

  return (
    <BlogsContext.Provider
      value={{
        requestState,
        createBlogCategory,
        createBlogPost,
        updateBlogPost,
        sendPostToDrafts,
        sendPostToArchive,
        sendPostToPublished,
        editBlogCategory,
        deleteBlogCategory,
      }}
    >
      {children}
    </BlogsContext.Provider>
  );
};

export default BlogsContextProvider;
