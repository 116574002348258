import { useState } from "react";
import Button from "../../../Components/Button/Button";
import Input from "../../../Components/Input/Input";
import ProfileSectionContainer from "../../../Components/ProfileSectionContainer/ProfileSectionContainer";
import classes from "./AddTutorContainer.module.css";
import AcceptedModal from "../../../Components/Modals/AcceptedModal/AcceptedModal";
import ConfirmAdministratorDetailsModal from "./AddTutorContainerModals/ConfirmTutorDetailsModal";
import { useNavigate } from "react-router-dom";
import TutorAccountCreatedModal from "./AddTutorContainerModals/TutorAccountCreatedModal";
import { addTutor } from "Services";
import errors from "Utilities/errors";
import { useApp } from "Context/AppContext";

const AddTutorContainer = () => {
  const navigate = useNavigate();
  const [displayConfirmAdministratorDetailsModal, ConfirmTutorDetailsModal] =
    useState(false);
  const [displayTutorAccountCreatedModal, setDisplayTutorAccountCreatedModal] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");

  const { setNotifications } = useApp();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await addTutor({ email, first_name: firstName });

      ConfirmTutorDetailsModal(false);
      setDisplayTutorAccountCreatedModal(true);
    } catch (err) {
      const errorMessage = errors.format(err);

      setNotifications([
        { title: errorMessage, severity: "error", id: Date.now() },
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {displayConfirmAdministratorDetailsModal && (
        <AcceptedModal
          onClick={() => {
            ConfirmTutorDetailsModal(false);
          }}
          body={
            <ConfirmAdministratorDetailsModal
              onClick={() => {
                ConfirmTutorDetailsModal(false);
              }}
              onClick2={handleSubmit}
              state={{ firstName, email }}
              isLoading={isSubmitting}
            />
          }
        />
      )}
      {displayTutorAccountCreatedModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayTutorAccountCreatedModal(false);
          }}
          body={
            <TutorAccountCreatedModal
              onClick={() => {
                setDisplayTutorAccountCreatedModal(false);
                navigate("/users/tutors");
              }}
            />
          }
        />
      )}
      <section className={classes.container}>
        <div className={classes.header}>
          <h1>Add new tutor</h1>
          <p>
            Add new tutor to the platform. Input their details and assign roles
            for specific permissions.
          </p>
        </div>

        <div className={classes.body}>
          <ProfileSectionContainer
            header="Tutor information"
            paragraph="Add personal details to create an account."
          >
            <Input
              isRequired
              type="text"
              label="First name"
              placeholder="E.g John"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />

            <Input
              isRequired
              type="email"
              label="Email address"
              placeholder="E.g name@gmail.com"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </ProfileSectionContainer>

          <div className={classes.buttonContainer}>
            <Button
              type="secondary"
              onClick={() => {
                navigate("/users/tutors");
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                ConfirmTutorDetailsModal(true);
              }}
              disabled={!firstName || !email || isSubmitting}
            >
              Continue
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddTutorContainer;
