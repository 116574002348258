import { createPortal } from "react-dom";
import { useEffect, ReactNode } from "react";

interface IProps {
  showModal?: boolean;
  children: ReactNode;
}
function Modal({ showModal, children }: IProps) {
  useEffect(() => {
    // Prevent body scroll on modal render
    const rootElement = document.getElementById("root");
    const bodyElement = document.body;

    if (!showModal) {
      bodyElement.style.overflow = "unset";

      if (rootElement) {
        rootElement.style.overflow = "unset";
      }
    } else {
      bodyElement.style.overflow = "hidden";

      if (rootElement) {
        rootElement.style.overflow = "hidden";
      }
    }
  }, [showModal]);

  if (!showModal) return null;

  return createPortal(
    <div
      className="fixed w-screen h-screen inset-0 z-10 flex items-center justify-center"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
    >
      <div>{children}</div>
    </div>,
    document.body
  );
}
export default Modal;
