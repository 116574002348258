import classes from "./BlogManagementModules.module.css";
import { AppContext } from "../../../Context/AppContext";
import React, { useContext, useEffect, useState } from "react";
import SectionsNav4 from "../../../Components/SectionsNav4/SectionsNav4";
import BlogMangementModulesEmptyTab from "./BlogMangementModulesEmptyTab";
import BlogCreatedContainer from "../BlogCreatedContainer/BlogCreatedContainer";
import { LinearProgress } from "@mui/material";

type BlogManagementModulesTypes = {
  blogPosts: any;
  navItems: any;
  setNavItems: any;
  blogCategories: any;
  activeFilter: any;
  loading: boolean;
};

const BlogManagementModules = ({
  blogPosts,
  navItems,
  setNavItems,
  blogCategories,
  activeFilter,
  loading,
}: BlogManagementModulesTypes) => {
  const { blogPost } = useContext(AppContext);

  //   Utils
  const activeComponent = navItems.find((data: any) => data.isActive);

  return (
    <section className={classes.container}>
      <div className={classes.sectionsNavSection}>
        <SectionsNav4 navItems={navItems} setNavItems={setNavItems} />
      </div>
      <div className={classes.body}>
        {loading && (
          <LinearProgress style={{ color: "#664efe" }} color="inherit" />
        )}
        {blogPosts?.length > 0 ? (
          <BlogCreatedContainer
            notShowEmptyTab={true}
            blogPost={blogPosts}
            activeFilter={activeFilter}
            blogCategories={blogCategories}
          />
        ) : (
          <BlogMangementModulesEmptyTab
            header={activeComponent.emptyState.header}
            paragraph={activeComponent.emptyState.paragraph}
          />
        )}
      </div>
    </section>
  );
};

export default BlogManagementModules;
