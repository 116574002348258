import { useParams } from "react-router-dom";
import useSWR from "swr";

import Layout from "Components/Layout/Layout";
import SchoolDetails from "../Components/SchoolDetails";

import endpoints from "Services/endpoints";
import { getSchoolBySlug } from "Services";

const ViewSchool = () => {
  const { schoolSlug } = useParams();
  const { data: response, isLoading } = useSWR(
    endpoints.GET_SCHOOL_BY_SLUG(schoolSlug || ""),
    getSchoolBySlug
  );

  return (
    <Layout>
      <div className="bg-white w-[940px] ml-6 mt-6 py-7 px-7 rounded-[10px] mb-10">
        <h2 className="text-[31px] font-medium mb-6">
          View School Information
        </h2>

        <SchoolDetails isLoading={isLoading} school={response?.data || {}} />
      </div>
    </Layout>
  );
};

export default ViewSchool;
