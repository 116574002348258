import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import classes from "./BlogCategoriesContainer.module.css";
import Button from "../../../Components/Button/Button";
import ellipse from "../../../Assets/Images/ellipses.svg";
import ActionsModal from "./ActionsModal/ActionsModal";
import { createCategoryDataType } from "../../../Types/requestTypes";

type BlogCategoriesContainerTypes = {
  blogCategories: any;
  createCategoryData: createCategoryDataType;
  setCreateCategoryData: Dispatch<SetStateAction<createCategoryDataType>>;
  setDisplayCreateBlogCategoryModal: Dispatch<SetStateAction<boolean>>;
  setActiveCategoryId: Dispatch<SetStateAction<string | null>>;
  setDisplayDeleteBlogPostModal: Dispatch<SetStateAction<boolean>>;
};

const BlogCategoriesContainer = ({
  blogCategories,
  setDisplayCreateBlogCategoryModal,
  setActiveCategoryId,
  setDisplayDeleteBlogPostModal,
}: BlogCategoriesContainerTypes) => {
  // State
  const [activeOptionIndex, setActiveOptionIndex] = useState<number | null>(
    null
  );

  // Refs
  const containerRefs = useRef<Array<HTMLDivElement | null>>(
    Array(blogCategories?.length).fill(null)
  );
  const actionModalRef = useRef<HTMLDivElement | null>(null);

  //   Utils
  const toggleOptions = (index: number) => {
    setActiveOptionIndex(activeOptionIndex === index ? null : index);
  };

  const closeOptions = (event: MouseEvent) => {
    if (
      actionModalRef.current &&
      actionModalRef.current.contains(event.target as Node)
    ) {
      // Clicked within action modal, do nothing
      return;
    }
    setActiveOptionIndex(null);
  };

  //   Effects
  useEffect(() => {
    document.addEventListener("mousedown", closeOptions);
    return () => {
      document.removeEventListener("mousedown", closeOptions);
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div>
          <h4>Categories</h4>
          <p>
            Keep content organised for search engines and users by adding or
            editing blog categories
          </p>
          <span>4/10</span>
        </div>
        <Button
          onClick={() => {
            setDisplayCreateBlogCategoryModal(true);
            setDisplayCreateBlogCategoryModal(true);
          }}
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1.5V17.5M17 9.5L1 9.5"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Add category</span>
        </Button>
      </div>
      <div className={classes.body}>
        {blogCategories?.map((data: any, index: number) => (
          <div key={index} className={classes.tableBody}>
            <span>{data?.title}</span>
            <span>{data?.post_count} posts</span>
            <div
              ref={(ref) => (containerRefs.current[index] = ref)}
              className={classes.ellipse}
              onClick={() => toggleOptions(index)}
            >
              <img src={ellipse} alt="more options" />
            </div>
            <div>
              {activeOptionIndex === index && (
                <div ref={actionModalRef} className={classes.popover}>
                  <ActionsModal
                    onClick={() => {
                      setActiveCategoryId(data?.id);
                      setDisplayCreateBlogCategoryModal(true);
                    }}
                    onClick2={() => {
                      setActiveCategoryId(data?.id);
                      setDisplayDeleteBlogPostModal(true);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogCategoriesContainer;
