import axios from "axios";
import endpoints from "./endpoints";

type TNullable<T> = T | null;

export type TUser = {
  id?: number;
  created_at?: TNullable<string>;
  update_at?: TNullable<string>;
  deleted_at?: TNullable<string>;
  first_name: TNullable<string>;
  last_name: TNullable<string>;
  profile_image: TNullable<string>;
  gender: TNullable<string>;
  is_default_password?: boolean;
  onboarding_complete?: boolean;
  phone_number?: TNullable<string>;
  email?: TNullable<string>;
  linkedIn_profile: TNullable<string>;
  twitter_profile?: TNullable<string>;
  bio: TNullable<string>;
  headline: TNullable<string>;
  country: TNullable<string>;
  state: TNullable<string>;
  dob?: TNullable<string>;
  specialization?: TNullable<string>;
  years_of_experience?: TNullable<string>;
  tech_proficiency?: TNullable<string>;
  certifications?: TNullable<string>;
  email_changed_at?: TNullable<string>;
  status?: TNullable<string>;
  deactivationReason?: TNullable<string>;
  employement_status?: TNullable<string>;
  education?: TNullable<string>;
};

export type TSchool = {
  id: number;
  image: TNullable<string | File>;
  name: TNullable<string>;
  description: TNullable<string>;
  slug: TNullable<string>;
  position: number;
  status: TNullable<string>;
  facts: TNullable<string>;
  salary_range: TNullable<string>;
  salary_range_text: TNullable<string>;
  career_path_no: TNullable<string>;
  facts_cover_image: TNullable<string | File>;
  tagline: TNullable<string>;
  tagline_title: TNullable<string>;
  courseCount: TNullable<number>;
  subtext: TNullable<string>;
};

export const baseURL = process.env.REACT_APP_API_URL + "/api/ise/v1";

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  (request) => {
    const iseAccessToken = localStorage.getItem("iseAdminAccessToken");
    request.headers.Authorization = `Bearer ${iseAccessToken}`;
    return request;
  },
  (error) => {
    return error;
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export type TTutor = {
  id: number;
  first_name: TNullable<string>;
  last_name: TNullable<string>;
  email: string;
  cohorts?: TCohort[];
};

export const getTutors = (payload = {}) => {
  return axiosInstance.get<{
    total: TNullable<number>;
    per_page: TNullable<number>;
    current_page: TNullable<number>;
    last_page: TNullable<number>;
    next_page_url: TNullable<string>;
    prev_page_url: TNullable<string>;
    from: TNullable<number>;
    to: TNullable<number>;
    data: TTutor[];
  }>("/tutors/all", {
    params: {
      ...payload,
    },
  });
};

export type TStudent = {
  id: number;
  first_name: TNullable<string>;
  last_name: TNullable<string>;
  email: TNullable<string>;
  gender: TNullable<string>;
  country: TNullable<string>;
  state: TNullable<string>;
  employement_status: TNullable<string>;
  status: TNullable<string>;
  education: TNullable<string>;
};

export const getStudents = (payload = {}) => {
  return axiosInstance.get<{
    total: TNullable<number>;
    per_page: TNullable<number>;
    current_page: TNullable<number>;
    last_page: TNullable<number>;
    next_page_url: TNullable<string>;
    prev_page_url: TNullable<string>;
    from: TNullable<number>;
    to: TNullable<number>;
    data: Partial<TStudent>[];
  }>("/students/all", {
    params: {
      ...payload,
    },
  });
};

export const getStudent = (url: string) => {
  return axiosInstance.get<TStudent>(url);
};

export const createSchool = (payload: FormData) => {
  return axiosInstance.post<TSchool>("/school/create-school", payload);
};

export const updateSchoolById = (id: string | number, payload: FormData) => {
  return axiosInstance.patch<TSchool>("/school/update/" + id, payload);
};

export const getSchoolBySlug = (url: string) => {
  return axiosInstance.get<TSchool>(url);
};

export const getSchools = (url: string) => {
  return axiosInstance.get<TSchool[]>(url);
};

export type TSchoolCourse = {
  courses_id: number;
  courses_name: string;
  courses_cover_image: string;
  courses_description: string;
  courses_slug: string;
  cohortcount: string;
  courses_overview: string;
};

export const getSchoolCoursesBySlug = (url: string) => {
  return axiosInstance.get<TSchoolCourse[]>(url);
};

export type TCohort = {
  id?: string;
  name: string;
  application_deadline: TNullable<string>;
  end_date?: TNullable<string>;
  start_date: TNullable<string>;
  status?: TNullable<string>;
  duration: TNullable<string>;
  price?: TNullable<number>;
  full_price: TNullable<number>;
  commitment: TNullable<string>;
  committment: TNullable<string>;
  location: TNullable<string>;
  tutor_id: TNullable<number | string>;
  certification: TNullable<boolean | string>;
  created_at?: string;
  tutor?: TTutor;
};

export type TCourseObjective = { id: number | string; value: string };

export type TCourse = {
  id: number;
  name: TNullable<string>;
  cover_image: TNullable<string | File>;
  description: TNullable<string>;
  slug: TNullable<string>;
  course_objective: TNullable<string | TCourseObjective[]>;
  publish: TNullable<boolean>;
  cohorts: TNullable<TCohort[]>;
  overview: TNullable<string>;
  difficulty_level: TNullable<string>;
  target_audiences: TNullable<{ category: string; description: string }[]>;
  website_title: TNullable<string>;
};

export const getCourseBySlug = (url: string) => {
  return axiosInstance.get<TCourse>(url);
};

export const createCourseByStep = (
  schoolId: string,
  payload: Partial<TCourse>
) => {
  return axiosInstance.post(
    `/courses/create-course/step1/${schoolId}`,
    payload
  );
};

export const updateCourseByStep = (
  courseId: string,
  step: string,
  payload: Partial<TCourse> | FormData
) => {
  return axiosInstance.patch(`/courses/${courseId}/update-${step}`, payload);
};

export const createCohort = (schoolId: string, payload: Partial<TCohort>) => {
  return axiosInstance.post(`/cohorts/create-cohort/${schoolId}`, payload);
};

export const updateCohortById = (
  cohortId: string,
  payload: Partial<TCohort>
) => {
  return axiosInstance.put(`/cohorts/${cohortId}`, payload);
};

export const getCohortsByCourseId = (url: string) => {
  return axiosInstance.get<TCohort[]>(url);
};

export const getCohortById = (url: string) => {
  return axiosInstance.get<TCohort>(url);
};

export const getMyProfile = () => {
  return axiosInstance.get(endpoints.GET_MY_PROFILE);
};

export const login = (payload: { email: string; password: string }) => {
  return axiosInstance.post(endpoints.LOGIN, payload);
};

export const getAdmins = (url: string) => {
  return axiosInstance.get(url);
};

export const getAdminById = (url: string) => {
  return axiosInstance.get(url);
};

export const toggleSchoolStatus = (schoolId: string) => {
  return axiosInstance.patch(endpoints.TOGGLE_SCHOOL_STATUS(schoolId));
};

export const deleteSchoolById = (schoolId: string) => {
  return axiosInstance.get(endpoints.DELETE_SCHOOL_BY_ID(schoolId));
};

export const deleteCourseById = (courseId: string) => {
  return axiosInstance.delete(endpoints.DELETE_COURSE_BY_ID(courseId));
};

export const getDashboardData = (url: string) => {
  return axiosInstance.get(url);
};

export const getTutorById = (url: string) => {
  return axiosInstance.get<TTutor>(url);
};

export const addTutor = (payload: { first_name: string; email: string }) => {
  return axiosInstance.post(endpoints.ADD_TUTOR, payload);
};
