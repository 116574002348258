import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { useMemo } from "react";

interface IProps {
  columns: any;
  data: any;
  isLoading: boolean;
}

const Table = (props: IProps) => {
  const { columns, data, isLoading } = props;
  const pageSize = 10;

  const currentData = useMemo(
    () => (isLoading ? Array(pageSize).fill({}) : data),
    [isLoading, data]
  );

  const currentColumns = useMemo(
    () =>
      isLoading
        ? columns?.map((column: any) => ({
            ...column,
            cell: () => <div className="bg-gray-200 h-[16px] w-[200px]"></div>,
          }))
        : columns,
    [isLoading, columns]
  );

  const { getRowModel, getHeaderGroups } = useReactTable({
    columns: currentColumns,
    data: currentData,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className="w-full">
      <thead>
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(
                (
                  header // map over the headerGroup headers array
                ) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="text-left pb-6"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                )
              )}
            </tr>
          );
        })}
      </thead>

      <tbody>
        {getRowModel().rows.map((row, index) => (
          <tr key={row.id} style={{ borderTop: "1px solid #dfe2e5" }}>
            {row.getVisibleCells().map((cell) => {
              return (
                <td key={cell.id} className="py-4">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        ))}

        <tr></tr>
      </tbody>
    </table>
  );
};

export default Table;
