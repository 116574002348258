import { ReactNode } from "react";

interface IProps {
  label?: string;
  children?: ReactNode;
  activeTab?: any;
  identifier: string | number;
  onChange?: (identifier: string | number) => void;
  className?: string;
}

const Tab = (props: IProps) => {
  const { label, children, activeTab, identifier, onChange, ...rest } = props;

  return (
    <button
      onClick={() => {
        onChange && onChange(identifier);
      }}
      {...rest}
    >
      {label || children}
    </button>
  );
};

export default Tab;
