import { ChangeEvent, Dispatch, SetStateAction } from "react";

import UploadInput from "./Components/UploadInput";
import Input from "Components/Input/Input";
import Button from "Components/Button/Button";

import { TSchool } from "Services";
import { useSearchParams } from "react-router-dom";

interface IProps {
  school: Partial<TSchool>;
  setSchool: Dispatch<SetStateAction<Partial<TSchool>>>;
  onCancel: () => void;
}

const Step3 = (props: IProps) => {
  const { school, setSchool, onCancel } = props;
  const [_, setSearchParams] = useSearchParams();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    setSchool((state) => ({ ...state, [target.name]: target.value }));
  };

  return (
    <>
      <Input
        isRequired
        label="Sub-text of annual salary range facts"
        name="salary_range_text"
        value={school.salary_range_text || ""}
        placeholder="Annual salary range of tech business leaders in the US"
        onChange={handleChange}
      />

      <Input
        isRequired
        label="Number of possible career paths"
        name="career_path_no"
        value={school.career_path_no || ""}
        placeholder="E.g 20+"
        onChange={handleChange}
      />

      <Input
        isRequired
        label="Subtext"
        name="subtext"
        value={school.subtext || ""}
        placeholder="E.g $80,000 - $150,000"
        onChange={handleChange}
      />

      <UploadInput
        label="Upload facts cover image"
        value={school.facts_cover_image || ""}
        onChange={(file) => {
          setSchool((state) => ({ ...state, facts_cover_image: file[0] }));
        }}
      />

      <div className="flex items-center justify-between border-t border-[#D8D8D8] pt-6 mt-10">
        <div>
          <button
            className="text-lg font-medium text-[#664EFE]"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>

        <div className="flex gap-x-6">
          <div className="w-[101px]">
            <Button
              type="secondary"
              onClick={() => {
                setSearchParams({ step: "2" });
              }}
            >
              Back
            </Button>
          </div>

          <div className="w-[275px]">
            <Button
              onClick={() => {
                setSearchParams({ step: "4" });
              }}
              disabled={
                !school.salary_range_text ||
                !school.career_path_no ||
                !school.subtext
              }
            >
              Review school information
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step3;
