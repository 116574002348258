import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import classes from "./BlogManagementContainer.module.css";
import HelloUser from "../../../Components/HelloUser/HelloUser";
import BlogManagementModules from "../BlogManagementModules/BlogManagementModules";
import Blogstatistics from "../Blogstatistics/Blogstatistics";
import BlogQuickStart from "../BlogQuickStart/BlogQuickStart";
import AcceptedModal from "../../../Components/Modals/AcceptedModal/AcceptedModal";
import CancelSchoolSuccessfulModal from "../../SchoolManagementPagesContainer/CreateSchoolPreview/PreviewModals/CancelSchoolSuccessfulModal";
import CreateBlogCategoryModal from "../CreateBlogUploadFile/CreateBlogCategoryModal";
import BlogCategoriesContainer from "../BlogCategoriesContainer/BlogCategoriesContainer";
import { createCategoryDataType } from "../../../Types/requestTypes";
import { BlogsContext } from "../../../Context/BlogsContext";
import { useBlogPosts, useGetBlogCategories } from "../../../Hooks/useBlog";
import Loader from "../../../Components/Loader/Loader";
import { mutate } from "swr";
import { backend_url } from "../../../Utilities/global";
import CancelSchoolCreationModal from "../../SchoolManagementPagesContainer/CreateSchoolPreview/PreviewModals/CancelSchoolCreationModal";
import deleteSvg from "../../../Assets/Images/deleteBlogPost.svg";

const BlogManagementContainer = () => {
  const navigate = useNavigate();

  //   Context
  const {
    requestState,
    createBlogCategory,
    editBlogCategory,
    deleteBlogCategory,
  } = useContext(BlogsContext);

  // States
  const [displayCreateBlogCategoryModal, setDisplayCreateBlogCategoryModal] =
    useState(false);
  const [
    displayCreateBlogCategorySuccessfulModal,
    setDisplayCreateBlogCategorySuccessfulModal,
  ] = useState(false);
  // States
  const [createCategoryData, setCreateCategoryData] =
    useState<createCategoryDataType>({
      title: "",
    });
  const [activeCategoryId, setActiveCategoryId] = useState<string | null>(null);
  const [navItems, setNavItems] = useState<any[]>([
    {
      title: "Published posts",
      isActive: true,
      statusFilter: "published",
      emptyState: {
        header: "No blogposts",
        paragraph:
          "Select “Create blogpost” to share insights, and connect with iṣẹ́’s audience. Once you publish a blogpost, it’ll appear here.",
      },
    },
    {
      title: "Drafts",
      isActive: false,
      statusFilter: "draft",
      emptyState: {
        header: "No blogpost drafts ",
        paragraph:
          "Once you cancel or save a blogpost to continue later it’ll appear here",
      },
    },
    {
      title: "Archives",
      isActive: false,
      statusFilter: "archived",
      emptyState: {
        header: "No archived blogposts",
        paragraph:
          "Once you temporarily deactivate a blogpost it’ll will appear here until you delete it permanently.",
      },
    },
  ]);
  const [displayDeleteBlogPostModal, setDisplayDeleteBlogPostModal] =
    useState(false);
  const [
    displayDeleteSuccessfulBlogPostModal,
    setDisplayDeleteSuccessfulBlogPostModal,
  ] = useState(false);

  // Utils
  const activeFilter = navItems.find((data) => data.isActive)?.statusFilter;

  // Requests
  const { isLoading, data } = useBlogPosts(activeFilter);
  const { isLoading: blogCategoriesIsLoading, data: blogCategoriesData } =
    useGetBlogCategories();

  const blogPosts = data?.data;
  const blogCategories = blogCategoriesData?.data;

  // Effects
  useEffect(() => {
    if (requestState.data) {
      if (requestState?.id === "create-blog-category") {
        setDisplayCreateBlogCategoryModal(false);
        setDisplayCreateBlogCategorySuccessfulModal(true);
      }

      if (requestState?.id === "edit-blog-category") {
        setDisplayCreateBlogCategoryModal(false);
        setDisplayCreateBlogCategorySuccessfulModal(true);
      }

      if (requestState?.id === "delete-blog-category") {
        setDisplayDeleteBlogPostModal(false);
        setDisplayDeleteSuccessfulBlogPostModal(true);
      }

      mutate(`${backend_url}/api/ise/v1/admin/blog/category`);
    }
  }, [requestState?.data]);

  if (blogCategoriesIsLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.container}>
      {displayCreateBlogCategoryModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayCreateBlogCategoryModal(false);
            setActiveCategoryId(null);
          }}
          body={
            <CreateBlogCategoryModal
              onClick={() => {
                setDisplayCreateBlogCategoryModal(false);
                setActiveCategoryId(null);
              }}
              onClick2={() => {
                activeCategoryId
                  ? editBlogCategory(
                      String(activeCategoryId),
                      createCategoryData
                    )
                  : createBlogCategory(createCategoryData);
              }}
              createCategoryData={createCategoryData}
              setCreateCategoryData={setCreateCategoryData}
              requestState={requestState}
              activeCategoryId={activeCategoryId}
              blogCategoriesData={blogCategories}
            />
          }
        />
      )}
      {displayCreateBlogCategorySuccessfulModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayCreateBlogCategorySuccessfulModal(false);
            setActiveCategoryId(null);
          }}
          body={
            <CancelSchoolSuccessfulModal
              buttonText="Done"
              header={`Category ${activeCategoryId ? "updated" : "created"}`}
              onClick={() => {
                setDisplayCreateBlogCategorySuccessfulModal(false);
              }}
            />
          }
        />
      )}

      {displayDeleteBlogPostModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayDeleteBlogPostModal(false);
          }}
          body={
            <CancelSchoolCreationModal
              imgSrc={deleteSvg}
              button1="Cancel"
              button2="Yes, Delete"
              header="Delete category?"
              paragraph="This is a permanent action, this category will be lost"
              onClick={() => {
                setDisplayDeleteBlogPostModal(false);
              }}
              onClick2={() => {
                deleteBlogCategory(String(activeCategoryId));
              }}
              isLoading={requestState?.isLoading}
            />
          }
        />
      )}

      {displayDeleteSuccessfulBlogPostModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayDeleteSuccessfulBlogPostModal(false);
            setActiveCategoryId(null);
          }}
          body={
            <CancelSchoolSuccessfulModal
              buttonText="Done"
              header="Blogpost deleted successfully!"
              onClick={() => {
                setDisplayDeleteSuccessfulBlogPostModal(false);
                setActiveCategoryId(null);
              }}
            />
          }
        />
      )}

      <HelloUser
        includeButton={true}
        header="Blog management"
        paragraph="Create, manage, improve and track blogposts performance easily."
      >
        <Button
          onClick={() => {
            navigate("/blogs/add-post?step=1");
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1V17M17 9L1 9"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Create new post</span>
        </Button>
      </HelloUser>
      <BlogQuickStart
        onClick={() => {
          setDisplayCreateBlogCategoryModal(true);
        }}
      />
      <Blogstatistics />
      <BlogManagementModules
        blogPosts={blogPosts}
        navItems={navItems}
        setNavItems={setNavItems}
        blogCategories={blogCategories}
        activeFilter={activeFilter}
        loading={isLoading}
      />
      <BlogCategoriesContainer
        blogCategories={blogCategories}
        createCategoryData={createCategoryData}
        setCreateCategoryData={setCreateCategoryData}
        setDisplayCreateBlogCategoryModal={setDisplayCreateBlogCategoryModal}
        setActiveCategoryId={setActiveCategoryId}
        setDisplayDeleteBlogPostModal={setDisplayDeleteBlogPostModal}
      />
    </div>
  );
};

export default BlogManagementContainer;
