type TIdentifierType = string | number | string[] | number[];

interface TabPanelProps {
  children?: React.ReactNode;
  identifier: TIdentifierType;
  value: string | number;
}

const identifierChecker = (
  identifier: TIdentifierType,
  value: string | number
) => {
  if (Array.isArray(identifier)) {
    return (identifier as any).includes(value);
  }

  return value === identifier;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, identifier, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={!identifierChecker(identifier, value)}
      id={`tabpanel-${identifier}`}
      aria-labelledby={`tab-${identifier}`}
      {...other}
    >
      {identifierChecker(identifier, value) && children}
    </div>
  );
};

export default TabPanel;
