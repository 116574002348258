import { Dispatch, SetStateAction } from "react";
import { TSchool } from "Services";

import classes from "./Step1.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextArea from "Components/TextArea/TextArea";
import Input from "Components/Input/Input";
import Button from "Components/Button/Button";

interface IProps {
  school: Partial<TSchool>;
  setSchool: Dispatch<SetStateAction<Partial<TSchool>>>;
}

const Step1 = (props: IProps) => {
  const { school, setSchool } = props;

  const [_, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <section className={classes.container}>
      <div>
        <Input
          isRequired
          label="Name of school"
          placeholder="E.g iṣẹ́ School of Business"
          name="name"
          onChange={(e) => {
            setSchool((state) => ({ ...state, name: e.target.value }));
          }}
          value={school.name || ""}
        />

        <Input
          isRequired
          label="School motto"
          placeholder="E.g Help businesses grow"
          name="tagline"
          onChange={(e) =>
            setSchool((state) => ({ ...state, tagline: e.target.value }))
          }
          value={school.tagline || ""}
        />

        <TextArea
          isRequired
          label="School description"
          placeholder="Start typing..."
          name="description"
          onChange={(e) => {
            setSchool((state) => ({ ...state, description: e.target.value }));
          }}
          value={school.description || ""}
        />
      </div>

      <div className={classes.buttonSection}>
        <Button
          type="secondary"
          onClick={() => {
            navigate("/schools");
          }}
        >
          <span>Cancel</span>
        </Button>

        <Button
          onClick={() => {
            setSearchParams({ step: "2" });
          }}
          disabled={!school.name || !school.tagline || !school.description}
        >
          <span>Next</span>
        </Button>
      </div>
    </section>
  );
};

export default Step1;
