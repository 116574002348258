import { Dispatch, SetStateAction } from "react";
import Button from "../../../Components/Button/Button";
import TextArea from "../../../Components/TextArea/TextArea";
import { requestType } from "../../../Context/AuthUserContext";
import { inputChangeHandler } from "../../../HelperFunctions/inputChangeHandler";
import classes from "./TutorProfileAccountDeactivation.module.css";

type DeleteDisclaimerModalBodyProps = {
  onClick: () => void;
  onClick2: () => void;
  state: requestType;
  reason: string;
  setReason: Dispatch<SetStateAction<string>>;
};

const Close = () => {
  return (
    <svg
      className="w-5 h-5"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 18.5L18 6.5M6 6.5L18 18.5"
        stroke="#2E2E2E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const DeleteDisclaimerModalBody = ({
  onClick,
  onClick2,
  state,
  reason,
  setReason,
}: DeleteDisclaimerModalBodyProps) => {
  return (
    <section className={classes.modalContainer}>
      <div className={classes.modalInnerContainer}>
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-[25px] font-medium">Deactivate account?</h2>

          <button onClick={onClick}>
            <Close />
          </button>
        </div>

        <hr />
        <p className="mt-4">
          Please provide reasons for requesting to deactivate this tutor’s
          account.
        </p>
        <TextArea
          isRequired
          label="Feedback"
          placeholder="Enter message here"
          value={reason}
          onChange={(e) => inputChangeHandler(e, setReason, true)}
        />

        <div className={classes.modalButtonSection}>
          {/* <Button type="invalid" onClick={onClick}>
            Cancel
          </Button> */}
          <button
            className="border-2 border-[#2e2e2e] h-[46px] w-[111px] rounded-[5px] font-medium"
            onClick={onClick}
          >
            Cancel
          </button>

          <Button
            type="delete"
            onClick={onClick2}
            loading={state?.isLoading}
            disabled={!reason}
          >
            Deactivate account
          </Button>
        </div>
      </div>
    </section>
  );
};

export default DeleteDisclaimerModalBody;
