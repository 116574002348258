import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

interface IProps {
  label?: string;
  onChange?: (file: File[]) => void;
  value: string | File;
}

const UploadInput = (props: IProps) => {
  const { label, onChange, value } = props;
  const [file, setFile] = useState<File[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles);
    onChange && onChange(acceptedFiles);
    // Do something with the files
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const getFileName = () => {
    if (!value) return null;

    if (typeof value === "string") {
      return value;
    }

    return (value as File)?.name;
  };

  const hasFile = () => value || file.length;

  return (
    <>
      <label className="flex flex-col">
        <span className="text-lg mb-1.5">{label}</span>

        <div
          className="h-[58px] border border-dashed rounded-[5px] border-[#737373] text-[#737373] flex items-center justify-center"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {!hasFile() ? (
            <span>
              Drag and drop file to attach it or{" "}
              <span className="font-medium">browse for a file...</span>
            </span>
          ) : (
            <span>{getFileName()}</span>
          )}
        </div>
      </label>

      <ul className="bg-[#FFEDAD] text-[13px] py-1.5 px-2.5 rounded-[5px] mt-2">
        <li>
          You can upload: <b>.svg or .png</b> files{" "}
        </li>

        <li>
          File size: <strong>1MB</strong> with maximum width and height of 750 X
          600px
        </li>
      </ul>
    </>
  );
};

export default UploadInput;
