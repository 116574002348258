import { TSchool } from "Services";

import Button from "Components/Button/Button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

interface IProps {
  isSubmitting?: boolean;
  isLoading?: boolean;
  school: Partial<TSchool>;
  onCancel?: () => void;
  onSubmit?: () => void;
}

function convertFileToImageURL(file: string | File) {
  if (!file) return "";

  if (typeof file === "string") {
    return file;
  }

  // Create an object URL for the file
  return URL.createObjectURL(file);
}

const SchoolDetails = (props: IProps) => {
  const { school, onCancel, onSubmit, isSubmitting } = props;
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();

  const currentStep = searchParams.get("step");

  return (
    <div>
      <section className="mb-8">
        <h4 className="text-[#737373] mb-2.5 text-[13px]">Name of school</h4>

        <p>{school.name}</p>
      </section>

      <section className="mb-8">
        <h4 className="text-[#737373] mb-2.5 text-[13px]">School tagline</h4>

        <p>{school.tagline}</p>
      </section>

      <section className="mb-8">
        <h4 className="text-[#737373] mb-2.5 text-[13px]">
          School description
        </h4>

        <p>{school.description}</p>
      </section>

      <section className="mb-8">
        <h4 className="text-[#737373] mb-2.5 text-[13px]">School image</h4>

        <div className="h-[500px] w-[500px]">
          <img
            src={convertFileToImageURL(school.image || "")}
            alt="school-image"
            className="h-full w-full object-cover"
          />
        </div>
      </section>

      <section className="mb-8">
        <h4 className="text-[#737373] mb-2.5 text-[13px]">Facts</h4>

        <p>{school.facts}</p>
      </section>

      <section className="mb-8">
        <h4 className="text-[#737373] mb-2.5 text-[13px]">
          Annual salary range
        </h4>

        <p>{school.salary_range}</p>
      </section>

      <section className="mb-8">
        <h4 className="text-[#737373] mb-2.5 text-[13px]">
          Sub- text of annual salary range facts
        </h4>

        <p>{school.salary_range_text}</p>
      </section>

      <section>
        <h4 className="text-[#737373] mb-2.5 text-[13px]">Facts cover image</h4>

        <div className="h-[602px] w-[507px]">
          <img
            src={convertFileToImageURL(school.facts_cover_image || "")}
            alt="facts_cover_image"
            className="h-full w-full object-cover"
          />
        </div>
      </section>

      <div className="flex items-center justify-between border-t border-[#D8D8D8] pt-6 mt-10">
        {currentStep && (
          <div>
            <button
              className="text-lg font-medium text-[#664EFE]"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        )}

        <div className="flex gap-x-6">
          <div className="w-[187px]">
            <Button
              type="secondary"
              onClick={() => navigate("/schools/" + school.slug + "?step=1")}
            >
              Edit Information
            </Button>
          </div>

          {currentStep && (
            <div className="min-w-[173px]">
              <Button loading={isSubmitting} onClick={onSubmit}>
                {id !== "create"
                  ? "Update School Information"
                  : "Create School"}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SchoolDetails;
