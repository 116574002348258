import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";
import classes from "./BlogPostCard.module.css";
import ellipse from "../../Assets/Images/ellipses.svg";
import ActionsModal from "./ActionsModal/ActionsModal";
import { useNavigate } from "react-router-dom";
import AcceptedModal from "../Modals/AcceptedModal/AcceptedModal";
import CancelSchoolCreationModal from "../../Containers/SchoolManagementPagesContainer/CreateSchoolPreview/PreviewModals/CancelSchoolCreationModal";
import CancelSchoolSuccessfulModal from "../../Containers/SchoolManagementPagesContainer/CreateSchoolPreview/PreviewModals/CancelSchoolSuccessfulModal";
import SchoolCreatedSuccessfulModal from "../../Containers/SchoolManagementPagesContainer/CreateSchoolPreview/PreviewModals/SchoolCreatedSuccessfulModal";

import ReportBlogPostModal from "./Modals/ReportBlogPostModal/ReportBlogPostModal";
import ShareBlogPostModal from "./Modals/ShareBlogPostModal/ShareBlogPostModal";
import { BlogsContext } from "../../Context/BlogsContext";
import { mutate } from "swr";
import { backend_url } from "../../Utilities/global";
import moment from "moment";

type setModalsType = Dispatch<SetStateAction<boolean>>;

type BlogPostCardProps = {
  id: number;
  image: string;
  title: string;
  date?: string;
  postStatus: string;
  courses?: string;
  category?: string;
  onClick?: () => void;
  setActiveId?: Dispatch<SetStateAction<number | null>>;
  setDisplayShareBlogPostModal: setModalsType;
  setDisplayReportBlogPostModal: setModalsType;
  setDisplayDraftBlogPostModal: setModalsType;
  setDisplayPublishBlogPostModal: setModalsType;
  setDisplayArchiveBlogPostModal: setModalsType;
  setDisplayRestoreBlogPostModal: setModalsType;
  setDisplayDeleteBlogPostModal: setModalsType;
};

const BlogPostCard = ({
  id,
  image,
  title,
  onClick,
  date,
  category,
  postStatus,
  setActiveId,
  setDisplayArchiveBlogPostModal,
  setDisplayDeleteBlogPostModal,
  setDisplayDraftBlogPostModal,
  setDisplayPublishBlogPostModal,
  setDisplayReportBlogPostModal,
  setDisplayRestoreBlogPostModal,
  setDisplayShareBlogPostModal,
}: BlogPostCardProps) => {
  // Router
  const navigate = useNavigate();

  // State
  const [showOptions, setShowOptions] = useState(false);

  // Refs
  const containerRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  // Utils
  const closeOptions = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node) &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target as Node)
    ) {
      setShowOptions(false);
    }
  };

  const toggleOptions = (id: number) => {
    setShowOptions(!showOptions);
    if (setActiveId) {
      setActiveId(id as number);
    }
  };

  // Effects
  useEffect(() => {
    document.addEventListener("mousedown", closeOptions);
    return () => {
      document.removeEventListener("mousedown", closeOptions);
    };
  }, []);

  return (
    <>
      <div className={classes.post} onClick={onClick}>
        <img src={image} alt={title} />
        <div className={classes.content}>
          <div className={classes.postInfo}>
            <h4>{title}</h4>
            <p>{category}</p>
            <span>{moment(date).format("DD MMMM, YYYY")}</span>
          </div>
          <div
            ref={containerRef}
            className={classes.ellipse}
            onClick={() => {
              toggleOptions(id);
            }}
          >
            <img src={ellipse} alt="more options" />
          </div>
          <div>
            {showOptions && (
              <div className={classes.popover} ref={optionsRef}>
                <ActionsModal
                  status={postStatus}
                  onClick={() => {
                    navigate(`/blogs/${id}/edit-post?step=1`);
                  }}
                  onClick2={() => {
                    navigate(`/blogs/${id}`);
                  }}
                  onClick3={() => {
                    setDisplayShareBlogPostModal(true);
                  }}
                  onClick4={() => {
                    setDisplayReportBlogPostModal(true);
                  }}
                  onClick5={() => {
                    setDisplayDraftBlogPostModal(true);
                  }}
                  onClick6={() => {
                    setDisplayPublishBlogPostModal(true);
                  }}
                  onClick7={() => {
                    setDisplayArchiveBlogPostModal(true);
                  }}
                  onClick8={() => {
                    setDisplayRestoreBlogPostModal(true);
                  }}
                  onClick9={() => {
                    setDisplayDeleteBlogPostModal(true);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPostCard;
