import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout/Layout";
import { useLocation, useSearchParams } from "react-router-dom";
import CreateBlogAddDetails from "../Containers/BlogManagementPageContainer/CreateBlogAddDetails/CreateBlogAddDetails";
import CreateBlogUploadFile from "../Containers/BlogManagementPageContainer/CreateBlogUploadFile/CreateBlogUploadFile";
import BlogManagementContainer from "../Containers/BlogManagementPageContainer/BlogManagementContainer/BlogManagementContainer";
import CreateBlogPreview from "../Containers/BlogManagementPageContainer/CreateBlogPreview/CreateBlogPreview";
import { createBlogpostDataType } from "../Types/requestTypes";
import Loader from "../Components/Loader/Loader";
import { useGetBlogCategories } from "../Hooks/useBlog";

const AddBlogPostPage = () => {
  // Router
  const location = useLocation();
  const [searchParams, setSearchparams] = useSearchParams();
  const userStep = searchParams.get("step");

  //   Formdata
  const [createBlogPostDataFormData, setCreateBlogPostDataFormData] = useState(
    new FormData()
  );

  // Requests
  const { isLoading, data } = useGetBlogCategories();
  const blogCategories = data?.data;

  //   States
  const [createBlogPostData, setCreateBlogPostData] =
    useState<createBlogpostDataType>({
      title: "",
      content: "",
      cover_image: { frontendFile: "", file: null },
      tags: [],
      reading_minutes: 0,
      categoryId: null,
      status: "published",
    });
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    setSearchparams((prevState) => {
      return { ...prevState, step: "1" };
    });
  }, []);

  useEffect(() => {
    const formData = new FormData();

    formData.append("title", createBlogPostData.title);
    formData.append("content", createBlogPostData.content);
    formData.append("cover_image", createBlogPostData.cover_image.file as File);
    formData.append("tags", createBlogPostData.tags.toString());
    formData.append(
      "reading_minutes",
      String(createBlogPostData.reading_minutes)
    );
    formData.append("categoryId", String(createBlogPostData.categoryId));
    formData.append("status", createBlogPostData.status);

    setCreateBlogPostDataFormData(formData);
  }, [createBlogPostData]);

  const activeCategoryTitle = blogCategories?.find((data: any) => {
    return data?.title === selectedCategory;
  })?.title;

  //   Effects

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout>
      {userStep === "1" ? (
        <CreateBlogAddDetails
          createBlogPostData={createBlogPostData}
          setCreateBlogPostData={setCreateBlogPostData}
        />
      ) : userStep === "2" ? (
        <CreateBlogUploadFile
          createBlogPostData={createBlogPostData}
          setCreateBlogPostData={setCreateBlogPostData}
          blogCategories={blogCategories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      ) : userStep === "3" ? (
        <CreateBlogPreview
          title={createBlogPostData.title}
          image={createBlogPostData.cover_image.frontendFile}
          content={createBlogPostData.content}
          category={activeCategoryTitle || ""}
          addTag={createBlogPostData?.tags}
          readTime={String(createBlogPostData.reading_minutes)}
          createBlogPostDataFormData={createBlogPostDataFormData}
          setCreateBlogPostData={setCreateBlogPostData}
        />
      ) : (
        <BlogManagementContainer />
      )}
    </Layout>
  );
};

export default AddBlogPostPage;
