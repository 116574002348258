import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../../Components/Breadcrumbs/Breadcrumbs";
import classes from "./CohortBoardContainer.module.css";
import HelloUser from "../../../Components/HelloUser/HelloUser";
import Button from "../../../Components/Button/Button";
import breadcrumbsBack from "../../../Assets/Images/breadcrumbsBack.svg";
import noResultFound from "../../../Assets/Images/noResultFound.svg";
import EmptyTabComponent from "../../../Components/EmptyTabComponent/EmptyTabComponent";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "Components/Table";
import Pagination from "Components/Table/Pagination";
import { getCohortsByCourseId, TCohort } from "Services";
import useSWR from "swr";
import endpoints from "Services/endpoints";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

const columnHelper = createColumnHelper<TCohort>();

const getStatusClass = (status: string) => {
  switch (status) {
    case "active":
      return "text-[#57970C] bg-[#EEFCDE]";
    case "closed":
      return "text-[#737373] bg-[#EFEFEF]";
    case "upcoming":
      return "bg-[#FEF7E5] text-[#C7920B]";
    default:
      return "";
  }
};

const CohortBoardContainer = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();

  const columns = [
    columnHelper.accessor("name", {
      header: "Cohort Name",
    }),
    columnHelper.accessor("created_at", {
      header: "Date created",
      cell: (data) =>
        format(new Date(data.getValue() || new Date()), "MMM d, yyyy"),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (data) => (
        <span
          className={`py-2 px-3 capitalize rounded-[2px] ${getStatusClass(
            data.getValue() || ""
          )}`}
        >
          {data.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor("id", {
      header: "",
      cell: (data) => (
        <Menu>
          <MenuButton>
            <svg
              width="24"
              height="44"
              viewBox="0 0 24 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 15L12 15.01M12 22L12 22.01M12 29L12 29.01M12 16C11.4477 16 11 15.5523 11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16ZM12 23C11.4477 23 11 22.5523 11 22C11 21.4477 11.4477 21 12 21C12.5523 21 13 21.4477 13 22C13 22.5523 12.5523 23 12 23ZM12 30C11.4477 30 11 29.5523 11 29C11 28.4477 11.4477 28 12 28C12.5523 28 13 28.4477 13 29C13 29.5523 12.5523 30 12 30Z"
                stroke="#2E2E2E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </MenuButton>

          <MenuItems
            anchor="bottom end"
            className="border rounded bg-white w-48"
          >
            <MenuItem disabled>
              <Link
                className="block data-[focus]:bg-gray-100 py-2 px-2.5"
                to={`/courses/${courseId}/cohorts/${data.getValue()}/view`}
              >
                View details
              </Link>
            </MenuItem>

            <hr />

            <MenuItem>
              <Link
                className="block data-[focus]:bg-gray-100 py-2 px-2.5"
                to={`/courses/${courseId}/cohorts/${data.getValue()}`}
              >
                Edit cohort
              </Link>
            </MenuItem>
          </MenuItems>
        </Menu>
      ),
    }),
  ];

  const [lastPage, setLastPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  // Requests
  // const { isLoading, data } = useTutors();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get("current_page") || 1;
  const searchQuery = searchParams.get("search") || "";

  const breadCrumbs = {
    image: breadcrumbsBack,
    array: [
      {
        title: `Course name`,
        route: `/course/${courseId}/cohorts`,
      },
      //   {
      //     title: `${activeCourse?.courseName}`,
      //     route: `/schools/${activeSchool?.schoolId}/courses`,
      //   },
      //   {
      //     title: "Cohort",
      //     route: `/schools/${SchoolId}/courses/${CourseId}/cohorts`,
      //   },
    ],
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");

  const handleFilterClick = (filterValue: string) => {
    setFilter(filterValue);
  };

  const handleRetrySearch = () => {
    setSearchTerm("");
  };

  //   const filteredCourseCohortData = activeCourse?.cohorts.filter((data) => {
  //     return (
  //       (data.cohortName.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //         searchTerm === "") &&
  //       (filter === "All" || data.status.toLowerCase() === filter.toLowerCase())
  //     );
  //   });

  const { data: response, isLoading: loadingCohorts } = useSWR(
    endpoints.GET_COHORTS_BY_COURSE_ID(courseId || ""),
    getCohortsByCourseId
  );

  return (
    <>
      <Breadcrumbs {...breadCrumbs} />

      <div className={classes.container}>
        <HelloUser
          includeButton={true}
          header="Cohort board"
          paragraph="Track, assign, and manage course cohort activities "
        >
          <Button
            onClick={() => {
              navigate(`/courses/${courseId}/cohorts/__create`);
            }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1V17M17 9L1 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Add cohort</span>
          </Button>
        </HelloUser>

        <div className={classes.tableContainer}>
          <div className={classes.topHeader}>
            <div className={classes.buttonContainer}>
              <button
                className={filter === "All" ? classes.active : ""}
                onClick={() => handleFilterClick("All")}
              >
                All
              </button>
              <button
                className={filter === "Active" ? classes.active : ""}
                onClick={() => handleFilterClick("Active")}
              >
                Active
              </button>
              <button
                className={filter === "Upcoming" ? classes.active : ""}
                onClick={() => handleFilterClick("Upcoming")}
              >
                Upcoming
              </button>
              <button
                className={filter === "Closed" ? classes.active : ""}
                onClick={() => handleFilterClick("Closed")}
              >
                Closed
              </button>
            </div>

            <div className={classes.inputSection}>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search by name"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                  stroke="#2E2E2E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>

          {!loadingCohorts && response?.data?.length ? (
            <>
              <div className="bg-white py-6 rounded-t-[10px]">
                <Table
                  columns={columns}
                  data={response.data}
                  isLoading={loadingCohorts}
                />
              </div>

              <Pagination
                pageNumber={Number(currentPage)}
                lastPage={lastPage}
                setPageNumber={(value) =>
                  setSearchParams({
                    search: searchQuery,
                    current_page: value.toString(),
                  })
                }
              />
            </>
          ) : (
            <EmptyTabComponent
              image={noResultFound}
              header={`No results for “${searchTerm}”`}
              firstParagraph="Try a new search"
              imageHeight={280}
              buttontext="Retry search"
              buttonType="null"
              buttonClicked={handleRetrySearch}
              buttonSvg={
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 1.5V5.25H1.93614M13.4536 6.75C13.0845 3.79027 10.5597 1.5 7.5 1.5C4.98197 1.5 2.82622 3.05113 1.93614 5.25M1.93614 5.25H5.25M13.5 13.5V9.75H13.0639M13.0639 9.75C12.1738 11.9489 10.018 13.5 7.5 13.5C4.44029 13.5 1.91549 11.2097 1.54642 8.25M13.0639 9.75H9.75"
                    stroke="#664EFE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CohortBoardContainer;
