import { useEffect, useState } from "react";
import classes from "./TutorsManagementModules.module.css";
import HelloUser from "../../../Components/HelloUser/HelloUser";
import Button from "../../../Components/Button/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import TutorsManagementModulesEmptyTab from "./TutorsManagementModulesEmptyTab";
import useTutors from "../../../Hooks/useTutors";
import Table from "../../../Components/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { getTutors } from "../../../Services";
import Pagination from "../../../Components/Table/Pagination";
import Filter from "../../../Components/Table/Filter";

type Nullable<T> = T | null;

type TTutor = {
  id: number;
  first_name: Nullable<string>;
  last_name: Nullable<string>;
  email: string;
};

const columnHelper = createColumnHelper<TTutor>();

const TutorsManagementModules = () => {
  const navigate = useNavigate();

  const columns = [
    columnHelper.accessor("first_name", {
      header: "Tutor name",
      cell: (data) => {
        const getFullName = () => {
          let result = "";

          if (data.row.original.first_name) {
            result += data.row.original.first_name;
          }

          if (data.row.original.last_name) {
            result += " " + data.row.original.last_name;
          }

          return result || "N.A";
        };

        const fullName = getFullName();

        return <div>{fullName}</div>;
      },
    }),
    columnHelper.accessor("email", {
      header: "Email address",
    }),
    columnHelper.accessor("last_name", {
      header: "Course",
      cell: () => <div>N.A</div>,
    }),
    columnHelper.accessor("id", {
      header: "",
      cell: (data) => (
        <button
          className="font-medium"
          onClick={() => navigate("/users/tutors/" + data.getValue())}
        >
          View
        </button>
      ),
    }),
  ];

  // States
  const [tutors, setTutors] = useState<TTutor[]>([]);
  const [lastPage, setLastPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  // Requests
  // const { isLoading, data } = useTutors();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get("current_page") || 1;
  const searchQuery = searchParams.get("search") || "";

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { data: response } = await getTutors({
          ...(currentPage && { page: currentPage }),
          ...(searchQuery && { search: searchQuery }),
        });

        setLastPage(response.last_page || 1);
        setTutors(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [currentPage, searchQuery]);

  return (
    <div className={classes.container}>
      <HelloUser
        includeButton={true}
        header="Tutors management board"
        paragraph="View, edit, communicate, and manage tutor information in this section."
      >
        <Button
          onClick={() => {
            navigate("/users/tutors/add-tutor");
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1V17M17 9L1 9"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Add tutor</span>
        </Button>
      </HelloUser>

      <div>
        {!isLoading && !tutors.length ? (
          <TutorsManagementModulesEmptyTab />
        ) : (
          <>
            <div className="mb-8">
              <Filter
                pageNumber={Number(currentPage)}
                lastPage={lastPage}
                pageSize={10}
                setPageNumber={(value) =>
                  setSearchParams({
                    search: searchQuery,
                    current_page: value.toString(),
                  })
                }
                handleChange={(value) => {
                  setSearchParams({ search: value });
                }}
              />
            </div>

            <div className="bg-white py-6 px-8 rounded-t-[10px]">
              <Table
                columns={columns}
                data={tutors || []}
                isLoading={isLoading}
              />
            </div>

            <Pagination
              pageNumber={Number(currentPage)}
              lastPage={lastPage}
              setPageNumber={(value) =>
                setSearchParams({
                  search: searchQuery,
                  current_page: value.toString(),
                })
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TutorsManagementModules;
