import { TCohort } from "Services";
import ProfileSectionContainer from "../../../Components/ProfileSectionContainer/ProfileSectionContainer";
import classes from "./TutorProfileCourseAssigned.module.css";
import { format } from "date-fns";

interface IProps {
  cohorts: TCohort[];
}

const TutorProfileCourseAssigned = (props: IProps) => {
  const { cohorts } = props;

  return (
    <ProfileSectionContainer
      header="Course assigned"
      paragraph="This shows the course this tutor is taking"
    >
      <div className={classes.container}>
        {cohorts.length ? (
          <>
            {cohorts.map((cohort, index) => (
              <div key={index}>
                <div>
                  <span>Course</span>
                  <h4>
                    {cohort.name} [
                    {format(
                      new Date(cohort.start_date || new Date()),
                      "dd MMM, yyyy"
                    )}
                    ]
                  </h4>
                </div>

                <div>
                  <span>School</span>
                  <h4>{"School of Engineering"}</h4>
                </div>
              </div>
            ))}
          </>
        ) : null}
      </div>
    </ProfileSectionContainer>
  );
};

export default TutorProfileCourseAssigned;
