import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

import Layout from "Components/Layout/Layout";

import {
  createSchool,
  getSchoolBySlug,
  TSchool,
  updateSchoolById,
} from "Services";
import SchoolProgress from "./Components/SchoolProgess";
import SchoolDetails from "../Components/SchoolDetails";
import SuccessModal from "Components/Modals/Success";
import AlertModal from "Components/Modals/Alert";
import errors from "Utilities/errors";
import { useApp } from "Context/AppContext";
import endpoints from "Services/endpoints";

const School = () => {
  // Router
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();

  const [school, setSchool] = useState<Partial<TSchool>>({
    image: "",
    name: "",
    description: "",
    tagline: "",
    tagline_title: "",
    facts: "",
    salary_range: "",
    subtext: "",
    career_path_no: "",
    salary_range_text: "",
    facts_cover_image: "",
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [schoolId, setSchoolId] = useState<number>();

  const currentStep = searchParams.get("step");

  //   Effects
  useEffect(() => {
    if (!currentStep) {
      setSearchParams({ step: "1" });
    }

    if (id !== "create") {
      //make api call
      getSchoolBySlug(endpoints.GET_SCHOOL_BY_SLUG(id || "")).then((res) => {
        setSchool({ ...res.data });
      });
    }
  }, []);

  const navigate = useNavigate();

  const { setNotifications } = useApp();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const schoolFormData = new FormData();

      const { image, facts_cover_image, ...rest } = school;

      Object.entries(rest).forEach(([key, value]) => {
        schoolFormData.append(key, value?.toString() || "");
      });

      schoolFormData.append("image", image || "");
      schoolFormData.append("facts_cover_image", facts_cover_image || "");

      if (id === "create") {
        const { data: response } = await createSchool(schoolFormData);

        setSchoolId(response.id);

        setShowSuccessModal(true);

        return;
      }

      await updateSchoolById(school.id || "", schoolFormData);

      setNotifications([
        {
          title: "Successfully updated school",
          severity: "success",
          id: Date.now(),
        },
      ]);

      setTimeout(() => {
        navigate("/schools");
      }, 1000);
    } catch (error) {
      const errorMessage = errors.format(error);

      setNotifications([
        { title: errorMessage, severity: "error", id: Date.now() },
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Layout>
      <SuccessModal
        showModal={showSuccessModal}
        title="School created successfully"
        message={`You've created ${school.name} on iṣẹ́ School. You can now proceed to add courses and customise the learning environment.`}
        primaryButtonText="Add Course"
        onPrimaryButtonClick={() => {
          navigate(`/schools/${schoolId}/add-course?step=1`);
        }}
        secondaryButtonText="Done"
        onSecondaryButtonClick={() => {
          navigate("/schools");
        }}
      />

      <AlertModal
        showModal={showAlertModal}
        title="Cancel school creation?"
        message="You'll lose all information and start over if you cancel."
        primaryButtonText="Yes, cancel"
        onPrimaryButtonClick={() => {
          navigate("/schools");
        }}
        secondaryButtonText="No, don't cancel"
        onSecondaryButtonClick={() => {
          setShowAlertModal(false);
        }}
      />

      <div className="bg-white w-[940px] ml-6 mt-6 py-7 px-7 rounded-[10px] mb-10">
        <SchoolProgress steps={[1, 2, 3, 4]} />

        <h2 className="text-[31px] font-medium mb-6">
          {currentStep === "4"
            ? "Review school information"
            : id !== "create"
            ? "Edit School"
            : "Create a new school"}
        </h2>

        <div>
          {currentStep === "1" ? (
            <Step1 school={school} setSchool={setSchool} />
          ) : currentStep === "2" ? (
            <Step2
              school={school}
              setSchool={setSchool}
              onCancel={() => {
                setShowAlertModal(true);
              }}
            />
          ) : currentStep === "3" ? (
            <Step3
              school={school}
              setSchool={setSchool}
              onCancel={() => {
                setShowAlertModal(true);
              }}
            />
          ) : currentStep === "4" ? (
            <SchoolDetails
              isSubmitting={isSubmitting}
              school={school}
              onCancel={() => {
                setShowAlertModal(true);
              }}
              onSubmit={handleSubmit}
            />
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default School;
