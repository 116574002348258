export function countWords(htmlContent: string) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;
  const textContent = tempDiv.textContent || tempDiv.innerText || "";

  const trimmedText = textContent.replace(/\s+/g, " ").trim();

  const words = trimmedText.length > 0 ? trimmedText.split(" ") : [];

  return words.length;
}
