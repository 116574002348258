import { backend_url } from "../Utilities/global";
import useGetHook from "./useGetHook";

export const useGetBlogCategories = () => {
  return useGetHook(`${backend_url}/api/ise/v1/admin/blog/category`, {
    revalidateOnFocus: false,
  });
};

export const useBlogPosts = (status: "published" | "draft") => {
  const url = status
    ? `${backend_url}/api/ise/v1/admin/blog/post/status?status=${status}`
    : null;

  return useGetHook(url, {
    revalidateOnFocus: false,
  });
};

export const useBlogPostsById = (id: string) => {
  const url = id ? `${backend_url}/api/ise/v1/admin/blog/post/${id}` : null;

  return useGetHook(url, {
    revalidateOnFocus: false,
  });
};
